import { TAction, TDispatch } from "../../../types";
import {
  AssignmentAction,
  AssignmentActionType,
  AssignmentModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const AssignmentDefault: AssignmentModel = {
  assignmentData: {
    data: [],
    total: 0,
  },
  assignmentDetail: {
    data: {} as any,
  },
  assignmentType: {
    data: [],
    total: 0,
  },
  executorData: {
    data: [],
  },
  executorRoleData: {
    data: [],
  },
  executorPositionData: {
    data: [],
  },
  executorUserData: {
    data: [],
  },
  executorPermissionData: {
    data: [],
  },
  errorHandler: {} as any// Add the error property
};

export const AssignmentReducer = (
  state: AssignmentModel = AssignmentDefault,
  action: AssignmentAction
): AssignmentModel => {
  switch (action.type) {
    case AssignmentActionType.GetAllAssignmentData:
      return { ...state, assignmentData: action.data };
    case AssignmentActionType.GetDetailAssignmentData:
      return { ...state, assignmentDetail: action.data };
    case AssignmentActionType.GetAssignmentTypeData:
      return { ...state, assignmentType: action.data };
    case AssignmentActionType.GetExecutorData:
      return { ...state, executorData: action.data };
    case AssignmentActionType.GetExecutorDataRoles:
      return { ...state, executorRoleData: action.data };
    case AssignmentActionType.GetExecutorDataPositions:
      return { ...state, executorPositionData: action.data };
    case AssignmentActionType.GetExecutorDataUsers:
      return { ...state, executorUserData: action.data };
    case AssignmentActionType.GetExecutorDataPermissions:
      return { ...state, executorPermissionData: action.data };
    case AssignmentActionType.HandleError:
      return {
        ...state,
        errorHandler: action.error // Store the error message in the state
      };
    default:
      return { ...state };
  }
};

export const AssignmentCommand = {
  GetAllAssignmentData: (params: any): TAction<AssignmentAction, void> => {
    return (dispatch: TDispatch<AssignmentAction>) => {
      return fetchAPI().get('/project/assignments', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: AssignmentActionType.GetAllAssignmentData,
        });
      });
    }
  },
  GetDetailAssignmentData: (params: any): TAction<AssignmentAction, void> => {
    return (dispatch: TDispatch<AssignmentAction>) => {
      return fetchAPI().get('/project/assignment', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: AssignmentActionType.GetDetailAssignmentData,
        });
      });
    }
  },
  GetAssignmentTypeData: (): TAction<AssignmentAction, void> => {
    return (dispatch: TDispatch<AssignmentAction>) => {
      return fetchAPI().get('/setting?key=config_project_assignment').then((response) => {
        dispatch({
          data: {
            data: response.data.data.value.type,
            total: response.data.data.value.type.length,
          },
          type: AssignmentActionType.GetAssignmentTypeData,
        });
      });
    };
  },
  CreateAssignmentData: (body: any): TAction<AssignmentAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<AssignmentAction>) => {
      try {
        const response = await fetchAPI().post('/project/assignment', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateAssignmentData: (body: any): TAction<AssignmentAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<AssignmentAction>) => {
      try {
        const response = await fetchAPI().patch('/project/assignment', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteAssignmentData: (body: any): TAction<AssignmentAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<AssignmentAction>) => {
      try {
        const response = await fetchAPI().delete('/project/assignment', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  GetExecutorData: (params: any): TAction<AssignmentAction, void> => {
    const typeRequest = params.type;
    delete params.type;
    return (dispatch: TDispatch<AssignmentAction>) => {
      return fetchAPI().get('/workflow/type', { params })
        .then((response) => {
          let type = AssignmentActionType.GetExecutorData;

          if (typeRequest === 'roles') {
            type = AssignmentActionType.GetExecutorDataRoles;
          } else if (typeRequest === 'positions') {
            type = AssignmentActionType.GetExecutorDataPositions;
          } else if (typeRequest === 'users') {
            type = AssignmentActionType.GetExecutorDataUsers;
          } else if (typeRequest === 'permissions') {
            type = AssignmentActionType.GetExecutorDataPermissions;
          }

          dispatch({
            data: {
              data: response.data.data.data,
            },
            type: type,
          });
        })
        .catch((error) => {
          // Handle the error here
          console.error('Error occurred:', error);
          throw error;
        });
    };
  },
  CreateExecutorData: (body: any): TAction<AssignmentAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<AssignmentAction>) => {
      try {
        const response = await fetchAPI().post('/project/assignment/executors', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteExecutorData: (body: any): TAction<AssignmentAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<AssignmentAction>) => {
      const response = await fetchAPI().delete('/project/assignment/executor', { params: body });
      return response;
    };
  },
};
