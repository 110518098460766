import { LatestActivityDataModel } from "../../shared/types";

export interface MonitoringRiskMitigationLEDDataModel {
  ulid: string;
  code: string;
  mitigation_description: string;
  status: string;
  loss_event_code: string;
  event_activity: string;
  event_description: string;
  event_type: string;
  event_cause: string;
  event_category: string;
  mitigation_action: string;
}

export interface MonitoringRiskMitigationLEDDetailDataModel {
  ulid: string;
  code: string;
  taxonomy_mitigation_type_id: string;
  taxonomy_mitigation_action_id: string;
  mitigation_description: string;
  due_date: string;
  taxonomy_mitigation_status_id: string | null;
  timeline_status: string | null;
  taxonomy_control_effectiveness_id: string | null;
  frequency_id: string | null;
  evidences: {
    filename: string;
    file_path: string;
  }[];
  notes: string | null;
  implementation_date: string | null;
  taxonomy_controlling_status_id: string | null;
  controller_evidences: {
    filename: string;
    file_path: string;
  }[];
  controller_notes: string | null;
  controller_date: string | null;
  status: string;
  updated_at: string;
  loss_event: {
    description: string;
    taxonomy_risk_activity_id: string;
    taxonomy_risk_description_id: string;
    taxonomy_risk_description_text: string;
    taxonomy_risk_category_id: string;
    taxonomy_sub_risk_category_id: string;
    taxonomy_sub_detail_risk_category_id: string;
    gross_amount: number;
    recovery_amount: number;
    net_loss_amount: number;
    currency_id: number;
  };
  stage: string | null;
  latest_activity: LatestActivityDataModel[] | null;
  risk_cause: {
    description: string;
  };
  loss_event_cause: {
    description: string;
  };
  risk_mitigation_pic: {
    position_id: string;
    user_id: string;
  }[];
  loss_event_mitigation_pic: {
    position_id: string;
    user_id: string;
  }[];
  loss_event_controlling_pic: {
    position_id: string;
    user_id: string;
  }[];
}

type TSub = {
  id: string;
  name: string;
};
type TSubWithParent = TSub & {
  parent_id: string;
};

type TTaxonomy = {
  risk_category: TSub[];
  sub_risk_category: TSubWithParent[];
  sub_detail_risk_category: TSubWithParent[];
  mitigation_type: TSub[];
  mitigation_status: TSub[];
  controlling_status: TSub[];
  mitigation_action: TSub[];
  mitigation_effectiveness: TSub[];
};

export interface MonitoringRiskMitigationLEDDependencyDataModel {
  division_name: string;
  directorate_name: string;
  project_assignment_id: string;
  taxonomy: TTaxonomy;
  frequency: TSub[];
}

export interface MonitoringRiskMitigationLEDModel {
  main: {
    data: MonitoringRiskMitigationLEDDataModel[];
    total: number;
  };
  detail: {
    data: MonitoringRiskMitigationLEDDetailDataModel;
  };
  dependency: {
    data: MonitoringRiskMitigationLEDDependencyDataModel;
  };
}

export enum MonitoringRiskMitigationLEDActionType {
  GetMonitoringRiskMitigationLED = "⌘➝Monitoring➝RiskMitigationLED➝GetMonitoringRiskMitigation",
  GetMonitoringRiskMitigationLEDDetail = "⌘➝Monitoring➝RiskMitigationLED➝GetMonitoringRiskMitigationDetail",
  GetMonitoringRiskMitigationLEDDependency = "⌘➝Monitoring➝RiskMitigationLED➝GetMonitoringRiskMitigationDependency",
}

export type MonitoringRiskMitigationLEDAction =
  | {
      data: {
        data: MonitoringRiskMitigationLEDDataModel[];
        total: number;
      };
      type: MonitoringRiskMitigationLEDActionType.GetMonitoringRiskMitigationLED;
    }
  | {
      data: {
        data: MonitoringRiskMitigationLEDDetailDataModel;
      };
      type: MonitoringRiskMitigationLEDActionType.GetMonitoringRiskMitigationLEDDetail;
    }
  | {
      data: {
        data: MonitoringRiskMitigationLEDDependencyDataModel;
      };
      type: MonitoringRiskMitigationLEDActionType.GetMonitoringRiskMitigationLEDDependency;
    };
