export interface AssignmentMainDataModel {
  ulid?: string;
  code: string;
  entity_group: string;
  type: string;
  entity: any;
  workflow: any;
  project: any;
  status: string;
}

export interface AssignmentTypeDataModel {
  type: string;
}

export interface ExecutorDataModel {
  id: string;
  name: string;
}

export interface AssignmentModel {
  assignmentData: {
    data: AssignmentMainDataModel[];
    total: number;
  };
  assignmentDetail: {
    data: AssignmentMainDataModel;
  };
  assignmentType: {
    data: AssignmentTypeDataModel[];
    total: number;
  },
  executorData: {
    data: ExecutorDataModel[];
  },
  executorRoleData: {
    data: ExecutorDataModel[];
  },
  executorPositionData: {
    data: ExecutorDataModel[];
  },
  executorUserData: {
    data: ExecutorDataModel[];
  },
  executorPermissionData: {
    data: ExecutorDataModel[];
  }
  errorHandler: {}
}


export enum AssignmentActionType {
  GetAllAssignmentData = "⌘➝Parameter Setting➝Assignment➝GetAllAssignmentData",
  GetDetailAssignmentData = "⌘➝Parameter Setting➝Assignment➝GetDetailAssignmentData",
  GetAssignmentTypeData = "⌘➝Parameter Setting➝Assignment➝GetAssignmentTypeData",
  GetExecutorData = "⌘➝Parameter Setting➝Assignment➝GetExecutorData",
  GetExecutorDataRoles = "⌘➝Parameter Setting➝Assignment➝GetExecutorDataRoles",
  GetExecutorDataPositions = "⌘➝Parameter Setting➝Assignment➝GetExecutorDataPositions",
  GetExecutorDataUsers = "⌘➝Parameter Setting➝Assignment➝GetExecutorDataUsers",
  GetExecutorDataPermissions = "⌘➝Parameter Setting➝Assignment➝GetExecutorDataPermissions",
  HandleError = "⌘➝Parameter Setting➝Assignment➝HandleError"
}

export type AssignmentAction = {
  data: {
    data: AssignmentMainDataModel[];
    total: number;
  };
  type: AssignmentActionType.GetAllAssignmentData;
} | {
  data: {
    data: AssignmentMainDataModel;
  };
  type: AssignmentActionType.GetDetailAssignmentData;
} | {
  data: {
    data: AssignmentTypeDataModel[];
    total: number;
  };
  type: AssignmentActionType.GetAssignmentTypeData;
} | {
  data: {
    data: ExecutorDataModel[];
  };
  type: AssignmentActionType.GetExecutorData;
} | {
  error: {}
  type: AssignmentActionType.HandleError;
} | {
  data: {
    data: ExecutorDataModel[];
  };
  type: AssignmentActionType.GetExecutorDataRoles;
} | {
  data: {
    data: ExecutorDataModel[];
  };
  type: AssignmentActionType.GetExecutorDataPositions;
} | {
  data: {
    data: ExecutorDataModel[];
  };
  type: AssignmentActionType.GetExecutorDataUsers;
} | {
  data: {
    data: ExecutorDataModel[];
  };
  type: AssignmentActionType.GetExecutorDataPermissions;
};
