export interface ConfigureUserRiskMgtDataModel {
  ulid?: string;
  name: string;
  username: string;
  email: string;
  unit: string;
  password: string;
  position: string;
  positions?: any[];
  roles?: Record<string, any>[];
  role: string[];
  group_id?: string;
}

export interface ConfigureAdminUserDataModel {
  ulid?: string;
  name: string;
  username: string;
  email: string;
  unit: string;
  password: string;
  position: string;
  role: string[];
}

export interface ConfigureUserLoginDataModel {
  user: {
    ulid: string;
    guid: string;
    domain: string;
    name: string;
    email: string;
    username: string;
    email_verified_at: string;
    properties: string;
    status: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  };
  token: string;
  refresh_token: string;
  abilities: string[];
  roles: any[];
  expires_at: string;
}

export interface ConfigureAllUserDataModel
  extends ConfigureUserRiskMgtDataModel {
  status: "active" | "inactive";
}

export interface ConfigureUserDelegationDataModel {
  ulid: string;
  status: string;
  date_start: string;
  date_end: string;
  note: string;
  properties: string;
  from_user: ConfigureUserRiskMgtDataModel;
  to_user: ConfigureUserRiskMgtDataModel;
}

export interface ConfigureUserModel {
  riskData: {
    data: ConfigureUserRiskMgtDataModel[];
    total: number;
  };
  allUser: {
    data: ConfigureAllUserDataModel[];
    total: number;
  };
  adminUser: {
    data: ConfigureUserRiskMgtDataModel[];
    total: number;
  };
  userLogin: {
    data: ConfigureUserLoginDataModel;
  };
  delegation: {
    data: ConfigureUserDelegationDataModel;
  };
}

export enum ConfigureUserActionType {
  GetUserRiskData = "⌘➝Configure➝User➝GetUserRiskData",
  GetAllUser = "⌘➝Configure➝User➝GetAllUser",
  GetAdminUser = "⌘➝Configure➝User➝GetAdminUser",
  UserLogin = "⌘➝Configure➝User➝UserLogin",
  GetUserDelegation = "⌘➝Configure➝User➝UserDelegation",
}

export type ConfigureUserAction =
  | {
      data: {
        data: ConfigureUserRiskMgtDataModel[];
        total: number;
      };
      type: ConfigureUserActionType.GetUserRiskData;
    }
  | {
      data: {
        data: ConfigureAllUserDataModel[];
        total: number;
      };
      type: ConfigureUserActionType.GetAllUser;
    }
  | {
      data: {
        data: ConfigureUserRiskMgtDataModel[];
        total: number;
      };
      type: ConfigureUserActionType.GetAdminUser;
    }
  | {
      data: {
        data: ConfigureUserLoginDataModel;
      };
      type: ConfigureUserActionType.UserLogin;
    }
  | {
      data: {
        data: ConfigureUserDelegationDataModel;
      };
      type: ConfigureUserActionType.GetUserDelegation;
    };
