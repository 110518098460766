export interface ConfigureRoleMgtDataModel {
  ulid?: string;
  role?: string;
  name: string;
  title: string;
  define_role?: string;
  permissions?: string[];
  status?: string;
  permission_count?: number;
  user_group?: any;
  is_system?: number;
}

export interface ConfigurePermissionModel {
  name: string;
  title: string;
}

export interface ConfigureRoleMgtModel {
  main: {
    data: ConfigureRoleMgtDataModel[];
    total: number;
  };
  permissions: {
    data: ConfigurePermissionModel[];
    total: number;
  }
  rolePermission: {
    data: ConfigurePermissionModel[];
    total: number;
  },
  roles: {
    data: ConfigurePermissionModel[];
    total: number;
  }
}

export enum ConfigureRoleMgtActionType {
  GetMainRoleMgt = "⌘➝Configure➝Role Management➝GetMainRoleMgt",
  GetAllPermissions = "⌘➝Configure➝Role Management➝GetAllPermissions",
  GetRolePermissions = "⌘➝Configure➝Role Management➝GetRolePermissions",
  GetRoleByGroupData = "⌘➝Configure➝Role Management➝GetRoleByGroupData",
}

export type ConfigureRoleMgtAction = {
  data: {
    data: ConfigureRoleMgtDataModel[];
    total: number;
  };
  type: ConfigureRoleMgtActionType.GetMainRoleMgt;
}  | {
  data: {
    data: ConfigurePermissionModel[];
    total: number;
  };
  type: ConfigureRoleMgtActionType.GetAllPermissions;
}  | {
  data: {
    data: ConfigurePermissionModel[];
    total: number;
  };
  type: ConfigureRoleMgtActionType.GetRolePermissions;
}  | {
  data: {
    data: ConfigureRoleMgtDataModel[];
    total: number;
  };
  type: ConfigureRoleMgtActionType.GetRoleByGroupData;
};
