import { combineReducers } from "redux";
import {
  KRIMonitoringCommand,
  KRIMonitoringDefault,
  KRIMonitoringReducer,
} from "./kri-monitoring/reducer";
import { KRIModel } from "./types";

const KRIReducer = combineReducers({
  kriMonitoring: KRIMonitoringReducer,
});

const KRIDefault: KRIModel = {
  kriMonitoring: KRIMonitoringDefault,
};

const KRICommand = {
  kriMonitoring: KRIMonitoringCommand,
};

export { KRIReducer, KRIDefault, KRICommand };
