import { AxiosResponse } from "axios";
import { fetchAPI } from "../../../../config/axios";
import { TAction, TDispatch } from "../../../types";
import {
  ConfigureUserDelegationAction,
  ConfigureUserDelegationActionType,
  ConfigureUserDelegationModel,
} from "./types";

export const ConfigureUserDelegationDefault: ConfigureUserDelegationModel = {
  main: {
    data: [],
    total: 0,
  },
};

export const ConfigureUserDelegationReducer = (
  state: ConfigureUserDelegationModel = ConfigureUserDelegationDefault,
  action: ConfigureUserDelegationAction
): ConfigureUserDelegationModel => {
  switch (action.type) {
    case ConfigureUserDelegationActionType.GetUserDelegationMain:
      return { ...state, main: action.data };

    default:
      return { ...state };
  }
};

export const ConfigureUserDelegationCommand = {
  GetUserDelegationMainData: (
    params?: unknown
  ): TAction<ConfigureUserDelegationAction, void> => {
    return (dispatch: TDispatch<ConfigureUserDelegationAction>) => {
      return fetchAPI()
        .get("/delegations", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data.data,
              total: response.data.data.total,
            },
            type: ConfigureUserDelegationActionType.GetUserDelegationMain,
          });
        });
    };
  },
  CreateUserDelegation: (
    body: unknown
  ): TAction<ConfigureUserDelegationAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserDelegationAction>) => {
      try {
        const response = await fetchAPI().post("/delegation", body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  SendEmail: (
    body: unknown
  ): TAction<ConfigureUserDelegationAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserDelegationAction>) => {
      try {
        const response = await fetchAPI().post("/delegation/send-email", body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteUserDelegation: (
    body: any
  ): TAction<ConfigureUserDelegationAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserDelegationAction>) => {
      try {
        const response = await fetchAPI().delete("/delegation", {
          params: body,
        });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
