import { combineReducers } from "redux";
import { ReportModel } from "./types";
import {
  ReportRiskProfileCommand,
  ReportRiskProfileDefault,
  ReportRiskProfileReducer,
} from "./risk-profile/reducers";
import {
  ReportLossEventCommand,
  ReportLossEventDefault,
  ReportLossEventReducer,
} from "./loss-event/reducer";
import {
  ReportKRICommand,
  ReportKRIDefault,
  ReportKRIReducer,
} from "./kri/reducer";

const ReportReducer = combineReducers({
  riskProfile: ReportRiskProfileReducer,
  lossEvent: ReportLossEventReducer,
  kri: ReportKRIReducer,
});

const ReportDefault: ReportModel = {
  riskProfile: ReportRiskProfileDefault,
  lossEvent: ReportLossEventDefault,
  kri: ReportKRIDefault,
};

const ReportCommand = {
  riskProfile: ReportRiskProfileCommand,
  lossEvent: ReportLossEventCommand,
  kri: ReportKRICommand,
};

export { ReportReducer, ReportDefault, ReportCommand };
