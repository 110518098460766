import axios from "axios";
import { notify } from "../utils";
// import { API_URL } from "../config/constants";

const fetchAPI = (type = "") => {
  let baseUrl = "" as any;

  if (type === "auth") {
    baseUrl = process.env.REACT_APP_SERVICE_AUTH;
  } else {
    if (type === "password") {
      baseUrl = process.env.REACT_APP_SERVICE?.replace("v1", "");
    } else {
      baseUrl = process.env.REACT_APP_SERVICE;
    }
  }

  const auth = localStorage.getItem("userinfo");
  const userinfo = auth ? JSON.parse(auth) : { token: null };

  const accessToken = userinfo.token;

  const api = axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": true,
      "Access-Control-Allow-Credentials": true,
      "Accept-Language": "en",
    },
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("userinfo");
        // eslint-disable-next-line no-restricted-globals
        location.href = "/login";
      }

      // notify.error({
      //   title: "Error",
      //   description: error.response?.data?.error || "Something went wrong!",
      // });

      return Promise.reject(error);
    }
  );

  return api;
};

export { fetchAPI };
