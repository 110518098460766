export interface CriteriaMainDataModel {
  ulid: string;
  name: string;
  code: string;
  description: string;
  group_id: string;
  status: string;
  matrix: any;
  matrix_level: any;
  group_ulid?: string;
  group?: any;
}

export interface CriteriaModel {
  criteriaData: {
    data: CriteriaMainDataModel[];
    total: number;
  };
  criteriaDetail: {
    data: CriteriaMainDataModel;
  }
}

export enum CriteriaActionType {
  GetAllCriteriaData = "⌘➝Parameter➝Setting➝Criteria➝Data",
  GetDetailCriteriaData = "⌘➝Parameter➝Setting➝Criteria➝Detail➝Data",
}

export type CriteriaAction = {
  data: {
    data: CriteriaMainDataModel[];
    total: number;
  };
  type: CriteriaActionType.GetAllCriteriaData;
} | {
  data: {
    data: CriteriaMainDataModel;
  };
  type: CriteriaActionType.GetDetailCriteriaData;
};
