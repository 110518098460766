import { TAction, TDispatch } from "../../../types";
import {
  TaxonomyAction,
  TaxonomyActionType,
  TaxonomyModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const TaxonomyDefault: TaxonomyModel = {
  taxonomyTree: {
    data: [],
    total: 0,
  },
  taxonomyChild: {
    data: [],
    total: 0,
  },
  taxonomyType: {
    data: [],
    total: 0,
  },
  taxonomyData: {
    data: [],
    total: 0,
  },
  taxonomyStatusType: {
    data: [],
    total: 0,
  },
};

export const TaxonomyReducer = (
  state: TaxonomyModel = TaxonomyDefault,
  action: TaxonomyAction
): TaxonomyModel => {
  switch (action.type) {
    case TaxonomyActionType.GetAllTaxonomyData:
      return { ...state, taxonomyData: action.data };
    case TaxonomyActionType.GetTaxonomyTreeData:
      return { ...state, taxonomyTree: action.data };
    case TaxonomyActionType.GetTaxonomyChildData:
      return { ...state, taxonomyChild: action.data };
    case TaxonomyActionType.GetTaxonomyTypeData:
      return { ...state, taxonomyType: action.data };
    case TaxonomyActionType.GetTaxonomyStatusType:
      return { ...state, taxonomyStatusType: action.data };

    default:
      return { ...state };
  }
};

export const TaxonomyCommand = {
  GetTaxonomyTreeData: (params: any): TAction<TaxonomyAction, void> => {
    return (dispatch: TDispatch<TaxonomyAction>) => {
      return fetchAPI().get('/taxonomy/chlids', { params }).then((response) => {
        const data = response.data.data;
        dispatch({
          data: {
            data: data,
            total: data.total,
          },
          type: TaxonomyActionType.GetTaxonomyTreeData,
        });
      });
    }
  },
  GetTaxonomyChildData: (params: any): TAction<TaxonomyAction, void> => {
    return (dispatch: TDispatch<TaxonomyAction>) => {
      return fetchAPI().get('/taxonomy/chlids', { params }).then((response) => {
        const data = response.data.data;
        dispatch({
          data: {
            data: data,
            total: data.total,
          },
          type: TaxonomyActionType.GetTaxonomyChildData,
        });
      });
    }
  },
  SearchTaxonomyTreeData: (params: any): TAction<TaxonomyAction, void> => {
    return (dispatch: TDispatch<TaxonomyAction>) => {
      return fetchAPI().get('/taxonomies', { params }).then((response) => {
        const data = response.data.data;
        dispatch({
          data: {
            data: data.data,
            total: data.total,
          },
          type: TaxonomyActionType.GetTaxonomyTreeData,
        });
      });
    }
  },
  GetTaxonomyTypeData: (): TAction<TaxonomyAction, void> => {
    return (dispatch: TDispatch<TaxonomyAction>) => {
      return fetchAPI().get('/setting?key=config_taxonomy').then((response) => {
        dispatch({
          data: {
            data: response.data.data.value.type,
            total: response.data.data.value.type.length,
          },
          type: TaxonomyActionType.GetTaxonomyTypeData,
        });
      });
    };
  },
  GetAllTaxonomyData: (params: any): TAction<TaxonomyAction, void> => {
    return (dispatch: TDispatch<TaxonomyAction>) => {
      return fetchAPI().get('/taxonomies', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: TaxonomyActionType.GetAllTaxonomyData,
        });
      });
    }
  },
  GetTaxonomyByGroupData: (params: any): TAction<TaxonomyAction, void> => {
    return (dispatch: TDispatch<TaxonomyAction>) => {
      return fetchAPI().get('/taxonomy/by-group', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: TaxonomyActionType.GetAllTaxonomyData,
        });
      });
    }
  },
  GetTaxonomyStatusType: (params: any): TAction<TaxonomyAction, void> => {
    return (dispatch: TDispatch<TaxonomyAction>) => {
      return fetchAPI().get('/taxonomy/status-type', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: response.data.data.length,
          },
          type: TaxonomyActionType.GetTaxonomyStatusType,
        });
      });
    }
  },
  CreateTaxonomyData: (body: any): TAction<TaxonomyAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<TaxonomyAction>) => {
      try {
        const response = await fetchAPI().post('/taxonomy', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateTaxonomyData: (body: any): TAction<TaxonomyAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<TaxonomyAction>) => {
      try {
        const response = await fetchAPI().patch('/taxonomy', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteTaxonomyData: (body: any): TAction<TaxonomyAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<TaxonomyAction>) => {
      try {
        const response = await fetchAPI().delete('/taxonomy', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
