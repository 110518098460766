export interface ConfigureSettingMainDataModel {
  key: string;
  value: { [key: string]: any };
}

export interface ConfigureSettingModel {
  main: {
    data: ConfigureSettingMainDataModel;
  };
  colorSetting: {
    data: ConfigureSettingMainDataModel;
  };
  imageSetting: {
    data: ConfigureSettingMainDataModel;
  };
  textSetting: {
    data: ConfigureSettingMainDataModel;
  };
  publicSetting: {
    data: ConfigureSettingMainDataModel;
  };
  riskScaleSetting: {
    data: ConfigureSettingMainDataModel;
  },
  nonLoginSetting: {
    data: ConfigureSettingMainDataModel;
  }
  // error?: string
  // response?: any;
}

export enum ConfigureSettingActionType {
  GetSettingMain = "⌘➝Configure➝Setting➝GetSettingMain",
  GetSettingColor = "⌘➝Configure➝Setting➝GetSettingColor",
  GetSettingImage = "⌘➝Configure➝Setting➝GetSettingImage",
  GetSettingText = "⌘➝Configure➝Setting➝GetSettingText",
  GetSettingPublic = "⌘➝Configure➝Setting➝GetSettingPublic",
  GetRiskScaleSetting = "⌘➝Configure➝Setting➝GetRiskScaleSetting",
  GetNonLoginSetting = "⌘➝Configure➝Setting➝GetNonLoginSetting",
}

export type ConfigureSettingAction = {
  data: {
    data: ConfigureSettingMainDataModel;
  };
  type: ConfigureSettingActionType.GetSettingMain;
}  | {
  data: {
    data: ConfigureSettingMainDataModel;
  };
  type: ConfigureSettingActionType.GetSettingColor;
}  | {
  data: {
    data: ConfigureSettingMainDataModel;
  };
  type: ConfigureSettingActionType.GetSettingImage;
}  | {
  data: {
    data: ConfigureSettingMainDataModel;
  };
  type: ConfigureSettingActionType.GetSettingText;
}  | {
  data: {
    data: ConfigureSettingMainDataModel;
  };
  type: ConfigureSettingActionType.GetSettingPublic;
}  | {
  data: {
    data: ConfigureSettingMainDataModel;
  };
  type: ConfigureSettingActionType.GetRiskScaleSetting;
}  | {
  data: {
    data: ConfigureSettingMainDataModel;
  };
  type: ConfigureSettingActionType.GetNonLoginSetting;
};
