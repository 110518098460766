import { ConfigureUserRiskMgtDataModel } from "../user/types";

export interface ConfigureUserDelegationMainDataModel {
  ulid: string;
  status: string;
  date_start: string;
  date_end: string;
  note: string;
  properties: string;
  from_user: ConfigureUserRiskMgtDataModel;
  to_user: ConfigureUserRiskMgtDataModel;
}

export interface ConfigureUserDelegationModel {
  main: {
    data: ConfigureUserDelegationMainDataModel[];
    total: number;
  };
}

export enum ConfigureUserDelegationActionType {
  GetUserDelegationMain = "⌘➝Configure➝User➝GetUserDelegationMain",
}

export type ConfigureUserDelegationAction = {
  data: {
    data: ConfigureUserDelegationMainDataModel[];
    total: number;
  };
  type: ConfigureUserDelegationActionType.GetUserDelegationMain;
};
