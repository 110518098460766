import { combineReducers } from "redux";
import { ConfigureModel } from "./types";
import {
  ConfigureUserCommand,
  ConfigureUserDefault,
  ConfigureUserReducer,
} from "./user/reducer";
import {
  ConfigureRoleMgtCommand,
  ConfigureRoleMgtDefault,
  ConfigureRoleMgtReducer,
} from "./role-management/reducer";
import {
  ConfigureAuditTrailsCommand,
  ConfigureAuditTrailsDefault,
  ConfigureAuditTrailsReducer,
} from "./audit-trails/reducer";
import {
  ConfigureUnitPICommand,
  ConfigureUnitPIDefault,
  ConfigureUnitPIReducer,
} from "./unit-pi/reducer";
import {
  ConfigureUserDelegationCommand,
  ConfigureUserDelegationDefault,
  ConfigureUserDelegationReducer,
} from "./user-delegation/reducer";
import {
  ConfigureEntityCommand,
  ConfigureEntityDefault,
  ConfigureEntityReducer,
} from "./entity/reducer";
import {
  ConfigureSettingCommand,
  ConfigureSettingDefault,
  ConfigureSettingReducer,
} from "./setting/reducer";
import {
  DashboardCommand,
  DashboardDefault,
  DashboardReducer,
} from "./dashboard/reducer";

const ConfigureReducer = combineReducers({
  user: ConfigureUserReducer,
  roleMgt: ConfigureRoleMgtReducer,
  auditTrails: ConfigureAuditTrailsReducer,
  unitPI: ConfigureUnitPIReducer,
  userDelegation: ConfigureUserDelegationReducer,
  entity: ConfigureEntityReducer,
  setting: ConfigureSettingReducer,
  dashboard: DashboardReducer,
});

const ConfigureDefault: ConfigureModel = {
  user: ConfigureUserDefault,
  roleMgt: ConfigureRoleMgtDefault,
  auditTrails: ConfigureAuditTrailsDefault,
  unitPI: ConfigureUnitPIDefault,
  userDelegation: ConfigureUserDelegationDefault,
  entity: ConfigureEntityDefault,
  setting: ConfigureSettingDefault,
  dashboard: DashboardDefault,
};

const ConfigureCommand = {
  user: ConfigureUserCommand,
  roleMgt: ConfigureRoleMgtCommand,
  auditTrails: ConfigureAuditTrailsCommand,
  unitPI: ConfigureUnitPICommand,
  userDelegation: ConfigureUserDelegationCommand,
  entity: ConfigureEntityCommand,
  setting: ConfigureSettingCommand,
  dashboard: DashboardCommand,
};

export { ConfigureReducer, ConfigureDefault, ConfigureCommand };
