  export interface TaxonomyMainDataModel {
    ulid: string;
    code: string;
    group_ulid: string;
    period: string;
    date_start: Date | string;
    date_end: Date | string;
    description: string;
    status: string;
    name: string;
    group?: any;
    parent?: any;
    level?: string;
    type?: string;

  }
  
  export interface TaxonomyTreeDataModel {
    ulid: string;
    code: string;
    name: string;
    description: string;
    level: string;
    type: string;
    properties: string;
    status: string;
    parent_ulid: string;
    group_ulid: string;
    group?: any;
    parent?: any;
    children?: any[];
    total_child?: any;
  }

  export interface TaxonomyTypeDataModel {
    type: string;
  }

  export interface TaxonomyStatusTypeDataModel {
    ulid: string;
    name: string;
    status_list: any[];
  }

  export interface TaxonomyModel {
    taxonomyTree: {
      data: TaxonomyTreeDataModel[];
      total: number;
    },
    taxonomyChild: {
      data: TaxonomyTreeDataModel[];
      total: number;
    },
    taxonomyType: {
      data: TaxonomyTypeDataModel[];
      total: number;
    },
    taxonomyData: {
      data: TaxonomyMainDataModel[];
      total: number;
    };
    taxonomyStatusType: {
      data: TaxonomyStatusTypeDataModel[];
      total: number;
    };
  }
  
  export enum TaxonomyActionType {
    GetTaxonomyTreeData = "⌘➝Parameter Setting➝RCSA Entity Period➝GetTaxonomyTreeData",
    GetTaxonomyChildData = "⌘➝Parameter Setting➝RCSA Entity Period➝GetTaxonomyChildData",
    GetTaxonomyTypeData = "⌘➝Parameter Setting➝RCSA Entity Period➝GetTaxonomyTypeData",
    GetEntityPeriodName = "⌘➝Parameter Setting➝RCSA Entity Period➝GetEntityPeriodName",
    GetAllTaxonomyData = "⌘➝Parameter Setting➝RCSA Entity Period➝GetAllTaxonomyData",
    GetTaxonomyStatusType = "⌘➝Parameter Setting➝RCSA Entity Period➝GetTaxonomyStatusType",
  }
  
  export type TaxonomyAction = {
    data: {
      data: TaxonomyMainDataModel[];
      total: number;
    };
    type: TaxonomyActionType.GetAllTaxonomyData;
  } | {
    data: {
      data: TaxonomyTreeDataModel[];
      total: number;
    };
    type: TaxonomyActionType.GetTaxonomyTreeData;
  } | {
    data: {
      data: TaxonomyTreeDataModel[];
      total: number;
    };
    type: TaxonomyActionType.GetTaxonomyChildData;
  } | {
    data: {
      data: TaxonomyTypeDataModel[];
      total: number;
    };
    type: TaxonomyActionType.GetTaxonomyTypeData;
  } | {
    data: {
      data: TaxonomyStatusTypeDataModel[];
      total: number;
    };
    type: TaxonomyActionType.GetTaxonomyStatusType;
  };
  