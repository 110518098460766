import { combineReducers } from "redux";
import {
  ParameterSettingCommand,
  ParameterSettingDefault,
  ParameterSettingReducer,
} from "./parameter-setting/reducer";
import { AppModel } from "./types";
import {
  ConfigureCommand,
  ConfigureDefault,
  ConfigureReducer,
} from "./configure/reducer";
import { RCSACommand, RCSADefault, RCSAReducer } from "./rcsa/reducer";
import { LEDCommand, LEDDefault, LEDReducer } from "./led/reducer";
import { KRICommand, KRIDefault, KRIReducer } from "./kri/reducer";
import { ReportCommand, ReportDefault, ReportReducer } from "./report/reducer";
import {
  MonitoringCommand,
  MonitoringDefault,
  MonitoringReducer,
} from "./monitoring/reducer";

const AppReducer = combineReducers({
  parameterSetting: ParameterSettingReducer,
  configure: ConfigureReducer,
  rcsa: RCSAReducer,
  led: LEDReducer,
  kri: KRIReducer,
  report: ReportReducer,
  monitoring: MonitoringReducer,
});

const AppDefault: AppModel = {
  parameterSetting: ParameterSettingDefault,
  configure: ConfigureDefault,
  rcsa: RCSADefault,
  led: LEDDefault,
  kri: KRIDefault,
  report: ReportDefault,
  monitoring: MonitoringDefault,
};

const AppCommand = {
  parameterSetting: ParameterSettingCommand,
  configure: ConfigureCommand,
  rcsa: RCSACommand,
  led: LEDCommand,
  kri: KRICommand,
  report: ReportCommand,
  monitoring: MonitoringCommand,
};

export { AppReducer, AppDefault, AppCommand };
