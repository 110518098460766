import { TAction, TDispatch } from "../../../types";
import {
  ConfigureSettingAction,
  ConfigureSettingActionType,
  ConfigureSettingMainDataModel,
  ConfigureSettingModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const ConfigureSettingDefault: ConfigureSettingModel = {
  main: {
    data: {} as any,
  },
  colorSetting: {
    data: {} as any,
  },
  imageSetting: {
    data: {} as any,
  },
  textSetting: {
    data: {} as any,
  },
  publicSetting: {
    data: {} as any,
  },
  riskScaleSetting: {
    data: {} as any,
  },
  nonLoginSetting: {
    data: {} as any,
  }
};

export const ConfigureSettingReducer = (
  state: ConfigureSettingModel = ConfigureSettingDefault,
  action: ConfigureSettingAction,
  setting?: string,
): ConfigureSettingModel => {
  switch (action.type) {
    case ConfigureSettingActionType.GetSettingMain:
      return { ...state, main: action.data };
    case ConfigureSettingActionType.GetSettingColor:
      return { ...state, colorSetting: action.data };
    case ConfigureSettingActionType.GetSettingImage:
      return { ...state, imageSetting: action.data };
    case ConfigureSettingActionType.GetSettingText:
      return { ...state, textSetting: action.data };
    case ConfigureSettingActionType.GetSettingPublic:
      return { ...state, publicSetting: action.data };
    case ConfigureSettingActionType.GetRiskScaleSetting:
      return { ...state, riskScaleSetting: action.data };
    case ConfigureSettingActionType.GetNonLoginSetting:
      return { ...state, nonLoginSetting: action.data };
    default:
      return { ...state };
  }
};

export const ConfigureSettingCommand = {
  GetSettingMainData: (params: any): TAction<ConfigureSettingAction, void> => {
    return (dispatch: TDispatch<ConfigureSettingAction>) => {
      return fetchAPI().get('/setting', { params }).then((response) => {
        let type = ConfigureSettingActionType.GetSettingMain;

        if (params.key === 'frontend_color_setting') {
          type = ConfigureSettingActionType.GetSettingColor
        } else if (params.key === 'frontend_image_setting') {
          type = ConfigureSettingActionType.GetSettingImage
        } else if (params.key === 'frontend_text_setting') {
          type = ConfigureSettingActionType.GetSettingText
        } else if (params.key === 'setting_public_common') {
          type = ConfigureSettingActionType.GetSettingPublic
        } else if (params.key === 'config_criteria') {
          type = ConfigureSettingActionType.GetRiskScaleSetting
        }

        dispatch({
          data: {
            data: response.data.data
          },
          type: type,
        });
      });
    };
  },
  GetSettingNonLogin: (): TAction<ConfigureSettingAction, void> => {
    return (dispatch: TDispatch<ConfigureSettingAction>) => {
      return fetchAPI().get('/setting/public').then((response) => {
        dispatch({
          data: {
            data: response.data.data
          },
          type: ConfigureSettingActionType.GetNonLoginSetting,
        });
      });
    };
  },
  UpdateSettingData: (body: any): TAction<ConfigureSettingAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureSettingAction>) => {
      try {
        const response = await fetchAPI().patch('/setting', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
