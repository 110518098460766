export interface ReportLossEventDataModel {
  ulid: string;
  code: string;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  sla_report: number;
  duration: number;
  location: string;
  frequency: number;
  criteria_inherent_impact: number;
  criteria_inherent_impact_level: string;
  criteria_inherent_impact_color: string;
  impact_operational: string;
  impact_reputation: string;
  impact_compilance: string;
  impact_business_effeciency: string;
  impact_systems: string;
  impact_other: string;
  impact_financial: string;
  gross_amount: number;
  recovery_amount: number;
  net_loss_amount: number;
  coa_number: string;
  coa_name: string;
  general_ledger_date: null;
  journal_information: string;
  incident_code: string;
  created_at: string;
  updated_at: string;
  entity: TEntity;
  position: TEntity;
  user: TEntity;
  taxonomy_risk_category: TEntity;
}

export type TEntity = {
  ulid: string;
  name: string;
};

export interface ReportLossEventFilterDataModel {
  groups: ReportLossEventFilterModel[];
  projects: ReportLossEventFilterModel[];
  entities: ReportLossEventFilterModel[];
  risk_officers: ReportLossEventFilterModel[];
  status: ReportLossEventFilterModel[];
  submission_status: ReportLossEventFilterModel[];
  implementation_status: ReportLossEventFilterModel[];
  incident_category: ReportLossEventFilterModel[];
}

export interface ReportLossEventFilterModel {
  key: string;
  value: string;
}

export interface ReportLossEventModel {
  main: {
    data: ReportLossEventDataModel[];
    total: number;
  };
  filter: {
    data: ReportLossEventFilterDataModel;
  };
}

export enum ReportLossEventActionType {
  GetLossEventFilter = "⌘➝Report➝LossEvent➝GetLossEventFilter",
  GetLossEvent = "⌘➝Report➝LossEvent➝GetLossEvent",
}

export type ReportLossEventAction =
  | {
      data: {
        data: ReportLossEventFilterDataModel;
      };
      type: ReportLossEventActionType.GetLossEventFilter;
    }
  | {
      data: {
        data: ReportLossEventDataModel[];
        total: number;
      };
      type: ReportLossEventActionType.GetLossEvent;
    };
