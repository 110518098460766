import { AxiosResponse } from "axios";
import { fetchAPI } from "../../../../config/axios";
import { TAction, TDispatch } from "../../../types";
import {
  ConfigureUserAction,
  ConfigureUserActionType,
  ConfigureUserModel,
} from "./types";

export const ConfigureUserDefault: ConfigureUserModel = {
  riskData: {
    data: [],
    total: 0,
  },
  allUser: {
    data: [],
    total: 0,
  },
  adminUser: {
    data: [],
    total: 0,
  },
  userLogin: {} as any,
  delegation: {} as any,
};

export const ConfigureUserReducer = (
  state: ConfigureUserModel = ConfigureUserDefault,
  action: ConfigureUserAction
): ConfigureUserModel => {
  switch (action.type) {
    case ConfigureUserActionType.GetUserRiskData:
      return { ...state, riskData: action.data };
    case ConfigureUserActionType.GetAllUser:
      return { ...state, allUser: action.data };
    case ConfigureUserActionType.GetAdminUser:
      return { ...state, adminUser: action.data };
    case ConfigureUserActionType.UserLogin:
      return { ...state, userLogin: action.data };
    case ConfigureUserActionType.GetUserDelegation:
      return { ...state, delegation: action.data };

    default:
      return { ...state };
  }
};

export const ConfigureUserCommand = {
  UserLogin: (
    body: any
  ): TAction<ConfigureUserAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      try {
        const response = await fetchAPI("auth").post("/login", body);
        await dispatch({
          data: {
            data: response.data.data,
          },
          type: ConfigureUserActionType.UserLogin,
        });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  LoginAsDelegation: (
    body: unknown
  ): TAction<ConfigureUserAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      try {
        const response = await fetchAPI().post("/user/delegation/login", body);
        await dispatch({
          data: {
            data: response.data.data,
          },
          type: ConfigureUserActionType.UserLogin,
        });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  LoadUserFromLocalStorage: (): TAction<
    ConfigureUserAction,
    AxiosResponse<any, any>
  > => {
    return (dispatch: TDispatch<ConfigureUserAction>) => {
      const userinfo = JSON.parse(localStorage.getItem("userinfo") || "{}");
      dispatch({
        data: {
          data: userinfo,
        },
        type: ConfigureUserActionType.UserLogin,
      });
      return userinfo;
    };
  },
  GetAdminUserData: (params: unknown): TAction<ConfigureUserAction, void> => {
    return (dispatch: TDispatch<ConfigureUserAction>) => {
      return fetchAPI()
        .get("/role/users", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data.data,
              total: response.data.data.total,
            },
            type: ConfigureUserActionType.GetAdminUser,
          });
        });
    };
  },
  GetUserRiskData: (params: unknown): TAction<ConfigureUserAction, void> => {
    return (dispatch: TDispatch<ConfigureUserAction>) => {
      return fetchAPI()
        .get("/role/users/by-role-group", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data.data,
              total: response.data.data.total,
            },
            type: ConfigureUserActionType.GetUserRiskData,
          });
        });
    };
  },
  GetAllUserData: (params: unknown): TAction<ConfigureUserAction, void> => {
    return (dispatch: TDispatch<ConfigureUserAction>) => {
      return fetchAPI()
        .get("/users", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data.data,
              total: response.data.data.total,
            },
            type: ConfigureUserActionType.GetAllUser,
          });
        });
    };
  },
  GetUserDelegation: (params?: unknown): TAction<ConfigureUserAction, void> => {
    return (dispatch: TDispatch<ConfigureUserAction>) => {
      return fetchAPI()
        .get("/user/delegation", { params })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data,
            },
            type: ConfigureUserActionType.GetUserDelegation,
          });
        });
    };
  },
  AttachUserPosition: (
    body: any
  ): TAction<ConfigureUserAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      try {
        const response = await fetchAPI().post("/user/position", body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DetachUserPosition: (
    body: any
  ): TAction<ConfigureUserAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      try {
        const response = await fetchAPI().delete("/user/position", {
          params: body,
        });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  AttachUserEntity: (
    body: any
  ): TAction<ConfigureUserAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      try {
        const response = await fetchAPI().post("/user/entity", body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DetachUserEntity: (
    body: any
  ): TAction<ConfigureUserAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      try {
        const response = await fetchAPI().delete("/user/entity", {
          params: body,
        });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  AttachUserRole: (
    body: any
  ): TAction<ConfigureUserAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      try {
        const response = await fetchAPI().patch("/user/assign-role", body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DetachUserRole: (
    body: any
  ): TAction<ConfigureUserAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      try {
        const response = await fetchAPI().patch("/user/remove-role", body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  ForgotPasswordUser: (
    body: any
  ): TAction<ConfigureUserAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      try {
        const response = await fetchAPI("password").post(
          "/forgot-password",
          body
        );
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  ResetPasswordUser: (
    body: any
  ): TAction<ConfigureUserAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      try {
        const response = await fetchAPI("password").post(
          "/reset-password",
          body
        );
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DownloadTemplateUser: (): TAction<ConfigureUserAction, void> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      const response = await fetchAPI().get(
        "/user/download/template?filename=template_a",
        {
          responseType: "blob", // Set responseType to 'blob' to handle binary data
        }
      );

      // Create a blob from the response data
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // Create a link element
      const link = document.createElement("a");

      // Set the download attribute and create a URL for the blob
      link.href = window.URL.createObjectURL(blob);
      link.download = "template_a.xlsx"; // Set the desired file name

      // Append the link to the document body and programmatically click it
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);
    };
  },
  DeleteUser: (
    body: any
  ): TAction<ConfigureUserAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      try {
        const response = await fetchAPI().delete(
          `/user?id=${body.id}&hard=${body.hard}`
        );
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateUser: (
    body: any
  ): TAction<ConfigureUserAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureUserAction>) => {
      try {
        const response = await fetchAPI().patch("/user", body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
