export interface KriTresholdMainDataModel {
  ulid: string;
  name: string;
  total_kri: number;
  code: string;
  description: string;
  status: string;
  group: KRITresholdGroupModel;
  tresholds: KRITresholdModels[];
}

export interface KRITresholdModels {
  ulid: string;
  code: string;
  name: string;
  description: string;
  status: string;
  priority: number;
  color: string;
  setting?: KRISettingModels;
}

export interface KRITresholdGroupModel {
  ulid: string;
  description: string;
  name: string;
  type: string;
}

export interface KRISettingModels {
  value: string;
  monitoring: number;
  priority: number;
}

export interface KriTresholdDetailDataModel {
  ulid: string;
  code: string;
  source: string;
  type: string;
  value: string;
  unit: string;
  date_start: string;
  date_end: string;
  reminder: string;
  remainded_at: string;
  frequency: string;
  sort_order: string;
  status: string;
  taxonomy?: any;
  entity?: any;
  tresholds: KRITresholdModels[];
  kri: KRIDataModel;
  project: KRITresholdsProjectModel;
}

export interface KRITresholdsProjectModel {
  code: string;
  description: string;
  name: string;
  status: string;
  tags: string;
  ulid: string;
}

export interface KRIDataModel {
  ulid: string;
  code: string;
  name: string;
  description: string;
  status: string;
  group: KRITresholdGroupModel;
}

export interface KriTresholdSettingModel {
  key: string;
  value: KRITresholdSettingValueModel;
}

export interface KRITresholdSettingValueModel {
  type: string[];
  unit: string[];
  source: string[];
  status: string[];
  source_type: any;
}

export interface KriTresholdTaxonomyModel {
  ulid: string;
  name: string;
}

export interface KriTresholdModel {
  main: {
    data: KriTresholdMainDataModel[];
    total: number;
  };
  kriDetail: {
    data: KriTresholdDetailDataModel[];
    total: number;
  };
  kriSetting: {
    data: KriTresholdSettingModel;
  };
  taxonomy: {
    data: KriTresholdTaxonomyModel[];
    total: number;
  };
  detail: {
    data: KriTresholdDetailDataModel;
  }
}

export enum KriTresholdActionType {
  GetKriTresholdMainData = "⌘➝Parameter Setting➝KriTreshold➝GetKriTresholdMainData",
  GetKriTresholdTaxonomyData = "⌘➝Parameter Setting➝KriTreshold➝GetKriTresholdTaxonomyData",
  GetAllDetailKRITresholdData = "⌘➝Parameter Setting➝KriTreshold➝GetAllDetailKRITresholdData",
  GetDetailKRITresholdData = "⌘➝Parameter Setting➝KriTreshold➝GetDetailKRITresholdData",
  GetSettingKRIData = "⌘➝Parameter Setting➝KriTreshold➝GetSettingKRIData",
}

export type KriTresholdAction = {
  data: {
    data: KriTresholdMainDataModel[];
    total: number;
  };
  type: KriTresholdActionType.GetKriTresholdMainData;
} | {
  data: {
    data: KriTresholdTaxonomyModel[];
    total: number;
  };
  type: KriTresholdActionType.GetKriTresholdTaxonomyData;
} | {
  data: {
    data: KriTresholdDetailDataModel[];
    total: number;
  };
  type: KriTresholdActionType.GetAllDetailKRITresholdData;
} | {
  data: {
    data: KriTresholdDetailDataModel;
  };
  type: KriTresholdActionType.GetDetailKRITresholdData;
} | {
  data: {
    data: KriTresholdSettingModel;
  };
  type: KriTresholdActionType.GetSettingKRIData;
};
