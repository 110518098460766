export interface DashboardChartDataModel {
  options: {
    chart: {
      type: string,
      height: string,
      width: string,
      toolbar: {
          show: boolean
      },
      zoom: {
          enabled: boolean
      },
      fontFamily: string | null,
      foreColor: string,
      sparkline: {
          enabled: boolean
      },
      stacked: boolean
    },
    plotOptions: {
      bar: {
          horizontal: boolean
      }
    },
    colors: string[],
    series: any[],
    dataLabels: {
        enabled: boolean
    },
    theme: {
        mode: string
    },
    title: {
        text: string
    },
    subtitle: {
        text: string,
        align: string
    },
    xaxis: {
        categories: any
    },
    grid: {
        show: boolean
    },
    markers: {
        show: boolean
    },
    legend: {
        show: boolean
    },
    labels: string[]
  },
  view_url: string,
}

export interface DashboardCountChartModel {
  title: string;
  total: number;
}

export interface DashboardKRITrendDataModel {
  title: string;
  trend: KRITrendModel[];
  status: string;
}

export interface KRITrendModel {
  label: string;
  color: string;
}

export interface DashboardFilterDataModel {
  groups: DashboardFilterModel[];
  projects: DashboardFilterModel[];
  entities: DashboardFilterModel[];
  positions: DashboardFilterModel[];
  risk_types: DashboardFilterModel[];
  risk_category_parents: DashboardFilterModel[];
  risk_category_childs: DashboardFilterModel[];
}

export interface DashboardFilterModel {
  key: string;
  value: string;
}

export interface DashboardModel {
  main: {
    data: DashboardKRITrendDataModel[];
  }
  kriTrend: {
    data: DashboardKRITrendDataModel[];
  };
  /* Chart RCSA */
    rcsaProgress: {
      data: DashboardChartDataModel;
    };
    rcsaTotalOfficer: {
      data: DashboardCountChartModel;
    };
    rcsaTotalDivision: {
      data: DashboardCountChartModel;
    };
    rcsaTotalRisk: {
      data: DashboardCountChartModel;
    };
    /* Global Chart */
    riskByType: {
      data: DashboardChartDataModel;
    };
    riskProgressWorkUnit: {
      data: DashboardChartDataModel;
    };
    riskByCategory: {
      data: DashboardChartDataModel;
    };
    riskMonitoringStatus: {
      data: DashboardChartDataModel;
    };
    riskMonitoringMitigationStatus: {
      data: DashboardChartDataModel;
    };
  /* Chart KRI */
    kriTrendList: {
      data: DashboardKRITrendDataModel[];
    }
    kriProgress: {
      data: DashboardChartDataModel;
    };
    kriReportResult: {
      data: DashboardChartDataModel;
    };
    kriMonitoringStatus: {
      data: DashboardChartDataModel;
    };
    kriMonitoringMitigationStatus: {
      data: DashboardChartDataModel;
    };
  /* Chart LED */
    ledTotalIncident: {
      data: DashboardCountChartModel;
    };
    ledTotalActualLosses: {
      data: DashboardCountChartModel;
    };
    ledProgressWorkUnit: {
      data: DashboardChartDataModel;
    };
    ledProgress: {
      data: DashboardChartDataModel;
    };
    ledTotalLossesPerUnit: {
      data: DashboardChartDataModel;
    };
    ledTop: {
      data: DashboardKRITrendDataModel[];
    };
    ledMonitoringStatus: {
      data: DashboardChartDataModel;
    };
    ledMonitoringMitigationStatus: {
      data: DashboardChartDataModel;
    };
  /* Filter Chart */
  filterDashboard: {
    data: DashboardFilterDataModel;
  }
}

export enum DashboardActionType {
  /* RCSA Chart */
    GetKRITrend = "⌘➝Dashboard➝GetKRITrend",
    GetDashboardChart = "⌘➝Dashboard➝GetDashboardChart",
    GetRiskProgress = "⌘➝Dashboard➝GetRiskProgress",
    GetRiskByType = "⌘➝Dashboard➝GetRiskByType",
    GetRiskProgressWorkUnit = "⌘➝Dashboard➝GetRiskProgressWorkUnit",
    GetRiskByCategory = "⌘➝Dashboard➝GetRiskByCategory",
    GetRiskMonitoringStatus = "⌘➝Dashboard➝GetRiskMonitoringStatus",
    GetRiskMonitoringMitigationStatus = "⌘➝Dashboard➝GetRiskMonitoringMitigationStatus",
    /* Count Chart */
    GetRiskTotalOfficer = "⌘➝Dashboard➝GetRiskTotalOfficer",
    GetRiskTotalDivision = "⌘➝Dashboard➝GetRiskTotalDivision",
    GetRiskTotalRisk = "⌘➝Dashboard➝GetRiskTotalRisk",
  /* KRI Chart */
    GetKRIProgress = "⌘➝Dashboard➝GetKRIProgress",
    GetKRIReportResult = "⌘➝Dashboard➝GetKRIReportResult",
    GetKRIMonitoringStatus = "⌘➝Dashboard➝GetKRIMonitoringStatus",
    GetKRIMonitoringMitigationStatus = "⌘➝Dashboard➝GetKRIMonitoringMitigationStatus",
    GetKRITrendList = "⌘➝Dashboard➝GetKRITrendList",
  /* LED Chart */
    GetLEDTotalIncident = "⌘➝Dashboard➝GetLEDTotalIncident",
    GetLEDTotalActualLosses = "⌘➝Dashboard➝GetLEDTotalActualLosses",
    GetLEDProgressWorkUnit = "⌘➝Dashboard➝GetLEDProgressWorkUnit",
    GetLEDProgress = "⌘➝Dashboard➝GetLEDProgress",
    GetLEDTotalLossesPerUnit = "⌘➝Dashboard➝GetLEDTotalLossesPerUnit",
    GetTopLED = "⌘➝Dashboard➝GetTopLED",
    GetLEDMonitoringStatus = "⌘➝Dashboard➝GetLEDMonitoringStatus",
    GetLEDMonitoringMitigationStatus = "⌘➝Dashboard➝GetLEDMonitoringMitigationStatus",

  /* Filter Chart */
  GetFilterDashboard = "⌘➝Dashboard➝GetFilterDashboard",
}

export type DashboardAction = {
  data: {
    data: DashboardKRITrendDataModel[];
  };
  type: DashboardActionType.GetDashboardChart;
} | {
  data: {
    data: DashboardKRITrendDataModel[];
  };
  type: DashboardActionType.GetKRITrend;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetRiskProgress;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetRiskByType;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetRiskProgressWorkUnit;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetRiskByCategory;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetRiskMonitoringStatus;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetRiskMonitoringMitigationStatus;
} | {
  data: {
    data: DashboardCountChartModel;
  };
  type: DashboardActionType.GetRiskTotalOfficer;
} | {
  data: {
    data: DashboardCountChartModel;
  };
  type: DashboardActionType.GetRiskTotalDivision;
} | {
  data: {
    data: DashboardCountChartModel;
  };
  type: DashboardActionType.GetRiskTotalRisk;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetKRIProgress;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetKRIMonitoringStatus;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetKRIMonitoringMitigationStatus;
} | {
  data: {
    data: DashboardKRITrendDataModel[];
  };
  type: DashboardActionType.GetKRITrendList;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetKRIReportResult;
} | {
  data: {
    data: DashboardCountChartModel;
  };
  type: DashboardActionType.GetLEDTotalIncident;
} | {
  data: {
    data: DashboardCountChartModel;
  };
  type: DashboardActionType.GetLEDTotalActualLosses;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetLEDProgressWorkUnit;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetLEDProgress;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetLEDTotalLossesPerUnit;
} | {
  data: {
    data: DashboardKRITrendDataModel[];
  };
  type: DashboardActionType.GetTopLED;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetLEDMonitoringStatus;
} | {
  data: {
    data: DashboardChartDataModel;
  };
  type: DashboardActionType.GetLEDMonitoringMitigationStatus;
} | {
  data: {
    data: DashboardFilterDataModel;
  };
  type: DashboardActionType.GetFilterDashboard;
};
