import { TAction, TDispatch } from "../../../types";
import { ProjectAction, ProjectActionType, ProjectModel } from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const ProjectDefault: ProjectModel = {
  projectData: {
    data: [],
    total: 0,
  },
  projectDetail: {
    data: {} as any,
  }
};

export const ProjectReducer = (
  state: ProjectModel = ProjectDefault,
  action: ProjectAction
): ProjectModel => {
  switch (action.type) {
    case ProjectActionType.GetAllProjectData:
      return { ...state, projectData: action.data };
    case ProjectActionType.GetDetailProjectData:
      return { ...state, projectDetail: action.data };

    default:
      return { ...state };
  }
};

export const ProjectCommand = {
  GetAllProjectData: (params: any): TAction<ProjectAction, void> => {
    return (dispatch: TDispatch<ProjectAction>) => {
      return fetchAPI().get('/projects', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ProjectActionType.GetAllProjectData,
        });
      });
    }
  },
  GetDetailProjectData: (params: any): TAction<ProjectAction, void> => {
    return (dispatch: TDispatch<ProjectAction>) => {
      return fetchAPI().get('/project', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: ProjectActionType.GetDetailProjectData,
        });
      });
    }
  },
  CreateProjectData: (body: any): TAction<ProjectAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ProjectAction>) => {
      try {
        const response = await fetchAPI().post('/project', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateProjectData: (body: any): TAction<ProjectAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ProjectAction>) => {
      try {
        const response = await fetchAPI().patch('/project', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteProjectData: (body: any): TAction<ProjectAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ProjectAction>) => {
      try {
        const response = await fetchAPI().delete('/project', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
