import { TAction, TDispatch } from "../../../types";
import {
  LEDLossEventAction,
  LEDLossEventActionType,
  LEDLossEventModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const LEDLossEventDefault: LEDLossEventModel = {
  main: {
    data: [],
    total: 0,
  },
  ledDetail: {
    data: {} as any,
  },
  dependency: {
    data: {} as any,
    total: 0,
  },
  pic: {
    data: [],
    total: 0,
  },
  rcsa: {
    data: [],
    total: 0,
  },
  unit: {
    data: [],
    total: 0,
  },
};

export const LEDLossEventReducer = (
  state: LEDLossEventModel = LEDLossEventDefault,
  action: LEDLossEventAction
): LEDLossEventModel => {
  switch (action.type) {
    case LEDLossEventActionType.GetAllLEDLossEvent:
      return { ...state, main: action.data };
    case LEDLossEventActionType.GetLEDLossEventDependency:
      return { ...state, dependency: action.data };
    case LEDLossEventActionType.GetPICLedLossEvent:
      return { ...state, pic: action.data };
    case LEDLossEventActionType.GetDetailLEDLossEvent:
      return { ...state, ledDetail: action.data };
    case LEDLossEventActionType.GetRCSALEDLossEvent:
      return { ...state, rcsa: action.data };
    case LEDLossEventActionType.GetUnitLEDLossEvent:
      return { ...state, unit: action.data };
    default:
      return { ...state };
  }
};

export const LEDLossEventCommand = {
  GetAllLEDLossEventLib: (params: any): TAction<LEDLossEventAction, void> => {
    return (dispatch: TDispatch<LEDLossEventAction>) => {
      return fetchAPI().get('/loss-event-library', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: LEDLossEventActionType.GetAllLEDLossEvent,
        });
      });
    };
  },
  GetAllLEDLossEvent: (params?: unknown): TAction<LEDLossEventAction, void> => {
    return (dispatch: TDispatch<LEDLossEventAction>) => {
      return fetchAPI().get('/loss-events', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: LEDLossEventActionType.GetAllLEDLossEvent,
        });
      });
    };
  },
  GetLEDLossEventDependency: (params?: unknown): TAction<LEDLossEventAction, void> => {
    return (dispatch: TDispatch<LEDLossEventAction>) => {
      return fetchAPI().get('/loss-event/dependency', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: 1,
          },
          type: LEDLossEventActionType.GetLEDLossEventDependency,
        });
      });
    };
  },
  GetDetailLEDLossEvent: (params?: unknown): TAction<LEDLossEventAction, void> => {
    return (dispatch: TDispatch<LEDLossEventAction>) => {
      return fetchAPI().get('/loss-event', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: LEDLossEventActionType.GetDetailLEDLossEvent,
        });
      });
    };
  },
  GetPICLedLossEvent: (params?: unknown): TAction<LEDLossEventAction, void> => {
    return (dispatch: TDispatch<LEDLossEventAction>) => {
      return fetchAPI().get('/loss-event/pics', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: response.data.data.length,
          },
          type: LEDLossEventActionType.GetPICLedLossEvent,
        });
      });
    };
  },
  GetRCSALEDLossEvent: (params?: unknown): TAction<LEDLossEventAction, void> => {
    return (dispatch: TDispatch<LEDLossEventAction>) => {
      return fetchAPI().get('/loss-event/risks', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: response.data.data.length,
          },
          type: LEDLossEventActionType.GetRCSALEDLossEvent,
        });
      });
    };
  },
  GetUnitLEDLibraryLossEvent: (params?: unknown): TAction<LEDLossEventAction, void> => {
    return (dispatch: TDispatch<LEDLossEventAction>) => {
      return fetchAPI().get('/loss-event-library/units', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: response.data.data.length,
          },
          type: LEDLossEventActionType.GetUnitLEDLossEvent,
        });
      });
    };
  },
  GetUnitLEDLossEvent: (params?: unknown): TAction<LEDLossEventAction, void> => {
    return (dispatch: TDispatch<LEDLossEventAction>) => {
      return fetchAPI().get('/loss-event/units', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: response.data.data.length,
          },
          type: LEDLossEventActionType.GetUnitLEDLossEvent,
        });
      });
    };
  },
  SubmitLEDLossEvent: (body?: any): TAction<LEDLossEventAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<LEDLossEventAction>) => {
      try {
        const response = await fetchAPI().post('/loss-event/submit', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  ApprovalLEDLossEvent: (body?: any): TAction<LEDLossEventAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<LEDLossEventAction>) => {
      try {
        const url = body.type === 'approve' ? '/loss-event/submit' : '/loss-event/reject';
        const response = await fetchAPI().post(url, body.body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  CreateLEDLossEvent: (body?: any): TAction<LEDLossEventAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<LEDLossEventAction>) => {
      try {
        const response = await fetchAPI().post('/loss-event', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateLEDLossEvent: (body: any): TAction<LEDLossEventAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<LEDLossEventAction>) => {
      try {
        const response = await fetchAPI().patch('/loss-event', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteLEDLossEvent: (body: any): TAction<LEDLossEventAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<LEDLossEventAction>) => {
      try {
        const response = await fetchAPI().delete('/loss-event', { data: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
