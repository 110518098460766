import { fetchAPI } from "../../../../config/axios";
import { TAction, TDispatch } from "../../../types";
import {
  ConfigureRoleMgtAction,
  ConfigureRoleMgtActionType,
  ConfigureRoleMgtModel,
} from "./types";
import { AxiosResponse } from "axios";

export const ConfigureRoleMgtDefault: ConfigureRoleMgtModel = {
  main: {
    data: [],
    total: 0,
  },
  permissions: {
    data: [],
    total: 0,
  },
  rolePermission: {
    data: [],
    total: 0,
  },
  roles: {
    data: [],
    total: 0,
  },
};

export const ConfigureRoleMgtReducer = (
  state: ConfigureRoleMgtModel = ConfigureRoleMgtDefault,
  action: ConfigureRoleMgtAction
): ConfigureRoleMgtModel => {
  switch (action.type) {
    case ConfigureRoleMgtActionType.GetMainRoleMgt:
      return { ...state, main: action.data };
    case ConfigureRoleMgtActionType.GetAllPermissions:
      return { ...state, permissions: action.data };
    case ConfigureRoleMgtActionType.GetRolePermissions:
      return { ...state, rolePermission: action.data };
    case ConfigureRoleMgtActionType.GetRoleByGroupData:
      return { ...state, main: action.data };
    default:
      return { ...state };
  }
};

export const ConfigureRoleMgtCommand = {
  GetMainRoleData: (params?: unknown): TAction<ConfigureRoleMgtAction, void> => {
    return (dispatch: TDispatch<ConfigureRoleMgtAction>) => {
      return fetchAPI().get('/roles', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureRoleMgtActionType.GetMainRoleMgt,
        });
      });
    };
  },
  CreateRoleData: (body: any): TAction<ConfigureRoleMgtAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureRoleMgtAction>) => {
      try {
        const response = await fetchAPI().post('/role', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateRoleData: (body: any): TAction<ConfigureRoleMgtAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureRoleMgtAction>) => {
      try {
        const response = await fetchAPI().patch('/role', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteRoleData: (body: any): TAction<ConfigureRoleMgtAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureRoleMgtAction>) => {
      try {
        const response = await fetchAPI().delete('/role', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  GetRolePermissions: (params?: any): TAction<ConfigureRoleMgtAction, void> => {
    return (dispatch: TDispatch<ConfigureRoleMgtAction>) => {
      return fetchAPI().get('/role/permissions', { params: params }).then((response) => {
        let data;
        let total;

        if (params.paginate !== undefined) {
          data = response.data.data;
          total = response.data.data.length;
        } else {
          data = response.data.data.data;
          total = response.data.data.total;
        }
        dispatch({
          data: {
            data: data,
            total: total,
          },
          type: ConfigureRoleMgtActionType.GetRolePermissions,
        });
      });
    };
  },
  GetRoleByGroupData: (params?: unknown): TAction<ConfigureRoleMgtAction, void> => {
    return (dispatch: TDispatch<ConfigureRoleMgtAction>) => {
      return fetchAPI().get('/roles/by-group', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: response.data.data.total,
          },
          type: ConfigureRoleMgtActionType.GetRoleByGroupData,
        });
      });
    };
  },
  GetAllPermissions: (params?: any): TAction<ConfigureRoleMgtAction, void> => {
    return (dispatch: TDispatch<ConfigureRoleMgtAction>) => {
      return fetchAPI().get('/permissions', { params }).then((response) => {
        let data;
        let total;
        if (params.paginate !== undefined) {
          data = response.data.data;
          total = response.data.data.length;
        } else {
          data = response.data.data.data;
          total = response.data.data.total;
        }

        dispatch({
          data: {
            data: data,
            total: total,
          },
          type: ConfigureRoleMgtActionType.GetAllPermissions,
        });
      });
    };
  },
  AssignRolePermission: (body: any): TAction<ConfigureRoleMgtAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureRoleMgtAction>) => {
      try {
        const response = await fetchAPI().post('/role/give-permission-to', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateRolePermission: (body: any): TAction<ConfigureRoleMgtAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureRoleMgtAction>) => {
      try {
        const response = await fetchAPI().patch('/role/permission', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
