import { TAction, TDispatch } from "../../../types";
import {
  KriTresholdAction,
  KriTresholdActionType,
  KriTresholdModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const KriTresholdDefault: KriTresholdModel = {
  main: {
    data: [],
    total: 0,
  },
  kriDetail: {
    data: [],
    total: 0,
  },
  kriSetting: {
    data: {} as any,
  },
  taxonomy: {
    data: [],
    total: 0,
  },
  detail: {
    data: {} as any,
  }
};
export const KriTresholdReducer = (
  state: KriTresholdModel = KriTresholdDefault,
  action: KriTresholdAction
): KriTresholdModel => {
  switch (action.type) {
    case KriTresholdActionType.GetKriTresholdMainData:
      return { ...state, main: action.data };
    case KriTresholdActionType.GetKriTresholdTaxonomyData:
      return { ...state, taxonomy: action.data };
    case KriTresholdActionType.GetAllDetailKRITresholdData:
      return { ...state, kriDetail: action.data };
    case KriTresholdActionType.GetDetailKRITresholdData:
      return { ...state, detail: action.data };
    case KriTresholdActionType.GetSettingKRIData:
      return { ...state, kriSetting: action.data };

    default:
      return { ...state };
  }
};

export const KriTresholdCommand = {
  GetAllKRITresholdData: (params: any): TAction<KriTresholdAction, void> => {
    return (dispatch: TDispatch<KriTresholdAction>) => {
      return fetchAPI().get('/kries', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: KriTresholdActionType.GetKriTresholdMainData,
        });
      });
    }
  },
  GetAllDetailKRITresholdData: (params: any): TAction<KriTresholdAction, void> => {
    return (dispatch: TDispatch<KriTresholdAction>) => {
      return fetchAPI().get('/kri/details', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: KriTresholdActionType.GetAllDetailKRITresholdData,
        });
      });
    }
  },
  GetDetailKRITresholdData: (params: any): TAction<KriTresholdAction, void> => {
    return (dispatch: TDispatch<KriTresholdAction>) => {
      return fetchAPI().get('/kri/detail', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: KriTresholdActionType.GetDetailKRITresholdData,
        });
      });
    }
  },
  GetKriTresholdTaxonomyData: (params: any): TAction<KriTresholdAction, void> => {
    return (dispatch: TDispatch<KriTresholdAction>) => {
      return fetchAPI().get('/kri/sources', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: response.data.data.length,
          },
          type: KriTresholdActionType.GetKriTresholdTaxonomyData,
        });
      });
    }
  },
  CreateKRITresholdData: (body: any): TAction<KriTresholdAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<KriTresholdAction>) => {
      try {
        const response = await fetchAPI().post('/kri', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateKRITresholdData: (body: any): TAction<KriTresholdAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<KriTresholdAction>) => {
      try {
        const response = await fetchAPI().patch('/kri', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteKRITresholdData: (body: any): TAction<KriTresholdAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<KriTresholdAction>) => {
      try {
        const response = await fetchAPI().delete('/kri', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  CreateKRIRiskDescriptionData: (body: any): TAction<KriTresholdAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<KriTresholdAction>) => {
      try {
        const response = await fetchAPI().post('/kri/risk-description', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateKRIRiskDescriptionData: (body: any): TAction<KriTresholdAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<KriTresholdAction>) => {
      try {
        const response = await fetchAPI().patch('/kri/risk-description', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteKRIRiskDescriptionData: (body: any): TAction<KriTresholdAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<KriTresholdAction>) => {
      try {
        const response = await fetchAPI().delete('/kri/risk-description', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  GetSettingKRIData: (): TAction<KriTresholdAction, void> => {
    return (dispatch: TDispatch<KriTresholdAction>) => {
      return fetchAPI().get('/setting?key=config_kri').then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: KriTresholdActionType.GetSettingKRIData,
        });
      });
    };
  },
};
