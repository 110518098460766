import { combineReducers } from "redux";
import {
  LEDLossEventCommand,
  LEDLossEventDefault,
  LEDLossEventReducer,
} from "./loss-event/reducer";
import { LEDModel } from "./types";

const LEDReducer = combineReducers({
  lossEvent: LEDLossEventReducer,
});

const LEDDefault: LEDModel = {
  lossEvent: LEDLossEventDefault,
};

const LEDCommand = {
  lossEvent: LEDLossEventCommand,
};

export { LEDReducer, LEDDefault, LEDCommand };
