import { TAction, TDispatch } from "../../../types";
import {
  KRIMonitoringAction,
  KRIMonitoringActionType,
  KRIMonitoringModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const KRIMonitoringDefault: KRIMonitoringModel = {
  main: {
    data: [],
    total: 0,
  },
  detail: {
    data: {} as any,
  },
  dependency: {
    data: {} as any,
  },
  pic: {
    data: [],
  },
  risk: {
    data: [],
  },
  incident: {
    data: {} as any,
  }
};
export const KRIMonitoringReducer = (
  state: KRIMonitoringModel = KRIMonitoringDefault,
  action: KRIMonitoringAction
): KRIMonitoringModel => {
  switch (action.type) {
    case KRIMonitoringActionType.GetKRIMonitoringMainData:
      return { ...state, main: action.data };
    case KRIMonitoringActionType.GetDetailKRIMonitoringData:
      return { ...state, detail: action.data };
    case KRIMonitoringActionType.GetDependencyKRIMonitoringData:
      return { ...state, dependency: action.data };
    case KRIMonitoringActionType.GetPICKRIMonitoringData:
      return { ...state, pic: action.data };
    case KRIMonitoringActionType.GetRiskKRIMonitoringData:
      return { ...state, risk: action.data };
    case KRIMonitoringActionType.GeKRIMonitoringIncidentData:
      return { ...state, incident: action.data };
    default:
      return { ...state };
  }
};

export const KRIMonitoringCommand = {
  GetAllKRIMonitoringData: (params: any): TAction<KRIMonitoringAction, void> => {
    return (dispatch: TDispatch<KRIMonitoringAction>) => {
      return fetchAPI().get('/kri-monitorings', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: KRIMonitoringActionType.GetKRIMonitoringMainData,
        });
      });
    }
  },
  GetDetailKRIMonitoringData: (params: any): TAction<KRIMonitoringAction, void> => {
    return (dispatch: TDispatch<KRIMonitoringAction>) => {
      return fetchAPI().get('/kri-monitoring', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: KRIMonitoringActionType.GetDetailKRIMonitoringData,
        });
      });
    }
  },
  GeKRIMonitoringIncidentData: (params: any): TAction<KRIMonitoringAction, void> => {
    return (dispatch: TDispatch<KRIMonitoringAction>) => {
      return fetchAPI().get('/kri-monitoring/incident-history', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: KRIMonitoringActionType.GeKRIMonitoringIncidentData,
        });
      });
    }
  },
  CreateKRIMonitoringData: (body: any): TAction<KRIMonitoringAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<KRIMonitoringAction>) => {
      try {
        const response = await fetchAPI().post('/kri-monitoring', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateKRIMonitoringData: (body: any): TAction<KRIMonitoringAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<KRIMonitoringAction>) => {
      try {
        const response = await fetchAPI().patch('/kri-monitoring', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  SubmitKRIMonitoringData: (body: any): TAction<KRIMonitoringAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<KRIMonitoringAction>) => {
      try {
        const response = await fetchAPI().post('/kri-monitoring/submit', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  RejectKRIMonitoringData: (body: any): TAction<KRIMonitoringAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<KRIMonitoringAction>) => {
      try {
        const response = await fetchAPI().post('/kri-monitoring/reject', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteKRIMonitoringData: (body: any): TAction<KRIMonitoringAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<KRIMonitoringAction>) => {
      try {
        const response = await fetchAPI().delete('/kri-monitoring', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  GetDependencyKRIMonitoringData: (params: any): TAction<KRIMonitoringAction, void> => {
    return (dispatch: TDispatch<KRIMonitoringAction>) => {
      return fetchAPI().get('/kri-monitoring/dependency', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: KRIMonitoringActionType.GetDependencyKRIMonitoringData,
        });
      });
    }
  },
  GetPICKRIMonitoringData: (params?: any): TAction<KRIMonitoringAction, void> => {
    return (dispatch: TDispatch<KRIMonitoringAction>) => {
      return fetchAPI().get('/kri-monitoring/pics', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: KRIMonitoringActionType.GetPICKRIMonitoringData,
        });
      });
    }
  },
  GetRiskKRIMonitoringData: (params?: any): TAction<KRIMonitoringAction, void> => {
    return (dispatch: TDispatch<KRIMonitoringAction>) => {
      return fetchAPI().get('/kri-monitoring/risks', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: KRIMonitoringActionType.GetRiskKRIMonitoringData,
        });
      });
    }
  },
};
