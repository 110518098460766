import { TAction, TDispatch } from "../../../types";
import {
  ReportLossEventAction,
  ReportLossEventActionType,
  ReportLossEventModel,
  ReportLossEventFilterDataModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";

export const ReportLossEventDefault: ReportLossEventModel = {
  main: {
    data: [] as any,
    total: 0,
  },
  filter: {
    data: {} as ReportLossEventFilterDataModel,
  },
};

export const ReportLossEventReducer = (
  state: ReportLossEventModel = ReportLossEventDefault,
  action: ReportLossEventAction
): ReportLossEventModel => {
  switch (action.type) {
    case ReportLossEventActionType.GetLossEventFilter:
      return { ...state, filter: action.data };
    case ReportLossEventActionType.GetLossEvent:
      return { ...state, main: action.data };
    default:
      return { ...state };
  }
};

export const ReportLossEventCommand = {
  GetLossEventFilter: (body: unknown): TAction<ReportLossEventAction, void> => {
    return (dispatch: TDispatch<ReportLossEventAction>) => {
      return fetchAPI()
        .post("/report/loss-event/filters", { filter: body })
        .then((response) => {
          dispatch({
            data: {
              data: response.data.data,
            },
            type: ReportLossEventActionType.GetLossEventFilter,
          });
        });
    };
  },
  GetLossEvent: (
    params: unknown,
    body: unknown
  ): TAction<ReportLossEventAction, void> => {
    return (dispatch: TDispatch<ReportLossEventAction>) => {
      return fetchAPI()
        .post("/report/loss-events", { filter: body }, { params })
        .then((response) => {
          const data = response.data.data;
          dispatch({
            data: {
              data: data.data,
              total: data.total,
            },
            type: ReportLossEventActionType.GetLossEvent,
          });
        });
    };
  },
};
