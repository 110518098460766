import { TAction, TDispatch } from "../../../types";
import { WorkflowAction, WorkflowActionType, WorkflowModel } from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const WorkflowDefault: WorkflowModel = {
  workflowData: {
    data: [],
    total: 0
  },
  workflowDetail: {
    data: {} as any,
  }
};

export const WorkflowReducer = (
  state: WorkflowModel = WorkflowDefault,
  action: WorkflowAction
): WorkflowModel => {
  switch (action.type) {
    case WorkflowActionType.GetAllWorkflowData:
      return { ...state, workflowData: action.data };
    case WorkflowActionType.GetDetailWorkflowData:
      return { ...state, workflowDetail: action.data as any};

    default:
      return { ...state };
  }
};

export const WorkflowCommand = {
  GetAllWorkflowData: (params: any): TAction<WorkflowAction, void> => {
    return (dispatch: TDispatch<WorkflowAction>) => {
      return fetchAPI().get('/workflows', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: WorkflowActionType.GetAllWorkflowData,
        });
      });
    }
  },
  GetDetailWorkflowData: (params: any): TAction<WorkflowAction, void> => {
    return (dispatch: TDispatch<WorkflowAction>) => {
      return fetchAPI().get('/workflow', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: WorkflowActionType.GetDetailWorkflowData,
        });
      });
    }
  },
  CreateWorkflowData: (body: any): TAction<WorkflowAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<WorkflowAction>) => {
      try {
        const response = await fetchAPI().post('/workflow', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateWorkflowData: (body: any): TAction<WorkflowAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<WorkflowAction>) => {
      try {
        const response = await fetchAPI().patch('/workflow', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteWorkflowData: (body: any): TAction<WorkflowAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<WorkflowAction>) => {
      try {
        const response = await fetchAPI().delete('/workflow', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },

  GetAllWorkflowStepData: (params: any): TAction<WorkflowAction, void> => {
    return (dispatch: TDispatch<WorkflowAction>) => {
      return fetchAPI().get('/workflow/steps', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: WorkflowActionType.GetAllWorkflowData,
        });
      });
    }
  },
  GetDetailWorkflowStepData: (params: any): TAction<WorkflowAction, void> => {
    return (dispatch: TDispatch<WorkflowAction>) => {
      return fetchAPI().get('/workflow/step', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: WorkflowActionType.GetDetailWorkflowData,
        });
      });
    }
  },
  CreateWorkflowStepData: (body: any): TAction<WorkflowAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<WorkflowAction>) => {
      try {
        const response = await fetchAPI().post('/workflow/step', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateWorkflowStepData: (body: any): TAction<WorkflowAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<WorkflowAction>) => {
      try {
        const response = await fetchAPI().patch('/workflow/step', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteWorkflowStepData: (body: any): TAction<WorkflowAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<WorkflowAction>) => {
      try {
        const response = await fetchAPI().delete('/workflow/step', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
