export interface ReportKRIDataModel {
  ulid: string;
  unit: string;
  risk_category_level_1: string;
  risk_category_level_2: string;
  risk_description: string;
  risk_description_text: string;
}

export interface ReportKRIFilterDataModel {
  groups: ReportKRIFilterModel[];
  projects: ReportKRIFilterModel[];
  entities: ReportKRIFilterModel[];
  risk_categories: ReportKRIFilterModel[];
  risk_implementation_status: ReportKRIFilterModel[];
  risk_levels: ReportKRIFilterModel[];
  risk_officers: ReportKRIFilterModel[];
  risk_status: ReportKRIFilterModel[];
  risk_submission_status: ReportKRIFilterModel[];
  positions: ReportKRIFilterModel[];
}

export interface ReportKRIFilterModel {
  key: string;
  value: string;
}

export interface ReportKRIModel {
  main: {
    data: ReportKRIDataModel[];
    total: number;
  };
  filter: {
    data: ReportKRIFilterDataModel;
  };
}

export enum ReportKRIActionType {
  GetKRIFilter = "⌘➝Report➝KRI➝GetKRIFilter",
  GetKRI = "⌘➝Report➝KRI➝GetKRI",
}

export type ReportKRIAction =
  | {
      data: {
        data: ReportKRIFilterDataModel;
      };
      type: ReportKRIActionType.GetKRIFilter;
    }
  | {
      data: {
        data: ReportKRIDataModel[];
        total: number;
      };
      type: ReportKRIActionType.GetKRI;
    };
