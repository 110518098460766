export interface WorkflowMainDataModel {
  code: string;
  name: string;
  type?: string;
  model?: string;
  description: string;
  group_code?: string;
  group_id?: string;
  status?: string;
  ulid?: string;
  group?: any;
  taxonomy?: any;
  taxonomy_id?: string,
  taxonomy_status_type_id?: string;
  taxonomy_status_type?: any,
  steps?: any,
}

export interface WorkflowModel {
  workflowData: {
    data: WorkflowMainDataModel[];
    total: number;
  };
  workflowDetail: {
    data: WorkflowMainDataModel;
  }
}

export enum WorkflowActionType {
  GetAllWorkflowData = "⌘➝Parameter Setting➝Workflow➝GetAllWorkflowData",
  GetDetailWorkflowData = "⌘➝Parameter Setting➝Workflow➝GetDetailWorkflowData",
}

export type WorkflowAction = {
  data: {
    data: WorkflowMainDataModel[];
    total: number;
  };
  type: WorkflowActionType.GetAllWorkflowData;
} | {
  data: {
    data: WorkflowMainDataModel;
  };
  type: WorkflowActionType.GetDetailWorkflowData;
};
