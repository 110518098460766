import { TAction, TDispatch } from "../../../types";
import {
  ConfigureEntityAction,
  ConfigureEntityActionType,
  ConfigureEntityModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const ConfigureEntityDefault: ConfigureEntityModel = {
  entity: {
    data: [],
    total: 0,
  },
  entityGroup: {
    data: [],
    total: 0,
  },
  entityGroupDetail: {
    data: {} as any,
    total: 0,
  },
  entityGroupType: {
    data: [],
    total: 0,
  },
  entityPosition: {
    data: [],
    total: 0,
  },
  entityPositionDetail: {
    data: {} as any,
  },
  entityTree: {
    data: [],
    total: 0,
  },
  entityChild: {
    data: [],
    total: 0,
  },
};

export const ConfigureEntityReducer = (
  state: ConfigureEntityModel = ConfigureEntityDefault,
  action: ConfigureEntityAction
): ConfigureEntityModel => {
  switch (action.type) {
    case ConfigureEntityActionType.GetAllEntity:
      return { ...state, entity: action.data };
    case ConfigureEntityActionType.GetAllEntityGroup:
      return { ...state, entityGroup: action.data };
    case ConfigureEntityActionType.GetDetailEntityGroup:
      return { ...state, entityGroupDetail: action.data };
    case ConfigureEntityActionType.GetAllEntityGroupType:
      return { ...state, entityGroupType: action.data };
    case ConfigureEntityActionType.GetAllEntityPosition:
      return { ...state, entityPosition: action.data };
    case ConfigureEntityActionType.GetDetailEntityPosition:
      return { ...state, entityPositionDetail: action.data };
    case ConfigureEntityActionType.GetParentEntityTree:
      return { ...state, entityTree: action.data };
    case ConfigureEntityActionType.GetChildEntityTree:
      return { ...state, entityChild: action.data };
    default:
      return { ...state };
  }
};

export const ConfigureEntityCommand = {
  /* Entity */
  GetAllEntityData: (params?: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/entities', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureEntityActionType.GetAllEntity,
        });
      });
    };
  },
  GetParentEntityTree: (params?: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/entity/chlids').then((response) => {
        const data = response.data.data;
        dispatch({
          data: {
            data: data,
            total: data.length,
          },
          type: ConfigureEntityActionType.GetParentEntityTree,
        });
      });
    };
  },
  GetChildEntityTree: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/entity/chlids', { params }).then((response) => {
        const data = response.data.data;
        dispatch({
          data: {
            data: data,
            total: data.length,
          },
          type: ConfigureEntityActionType.GetChildEntityTree,
        });
      });
    };
  },
  CreateEntityData: (body: any): TAction<ConfigureEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      try {
        const response = await fetchAPI().post('/entity', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateEntityData: (body: any): TAction<ConfigureEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      try {
        const response = await fetchAPI().patch('/entity', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteEntityData: (body: any): TAction<ConfigureEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      try {
        const response = await fetchAPI().delete('/entity', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  /* End Entity */

  /* Entity Group */
  GetAllEntityGroupData: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/groups', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureEntityActionType.GetAllEntityGroup,
        });
      });
    };
  },
  GetDetailEntityGroupData: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/group', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: 0,
          },
          type: ConfigureEntityActionType.GetDetailEntityGroup,
        });
      });
    };
  },
  CreateEntityGroupData: (body: any): TAction<ConfigureEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      try {
        const response = await fetchAPI().post('/group', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateEntityGroupData: (body: any): TAction<ConfigureEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      try {
        const response = await fetchAPI().patch('/group', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteEntityGroupData: (body: any): TAction<ConfigureEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      try {
        const response = await fetchAPI().delete('/group', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  GetEntityByGroupData: (params?: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/group/entities', { params: params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureEntityActionType.GetAllEntity,
        });
      });
    };
  },
  GetAllEntityGroupTypeData: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/setting', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.value.type,
            total: 0,
          },
          type: ConfigureEntityActionType.GetAllEntityGroupType,
        });
      });
    };
  },
  /* End Entity Group */

  /* Entity Position */
  GetAllEntityPosition: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/positions', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureEntityActionType.GetAllEntityPosition,
        });
      });
    };
  },
  GetDetailEntityPosition: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/position', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
            total: 1,
          },
          type: ConfigureEntityActionType.GetDetailEntityPosition,
        });
      });
    };
  },
  GetEntityPositionByEntity: (params: unknown): TAction<ConfigureEntityAction, void> => {
    return (dispatch: TDispatch<ConfigureEntityAction>) => {
      return fetchAPI().get('/entity/positions', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureEntityActionType.GetAllEntityPosition,
        });
      });
    };
  },
  CreateEntityPositionData: (body: any): TAction<ConfigureEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      try {
        const response = await fetchAPI().post('/position', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateEntityPositionData: (body: any): TAction<ConfigureEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      try {
        const response = await fetchAPI().patch('/position', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteEntityPositionData: (body: any): TAction<ConfigureEntityAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      try {
        const response = await fetchAPI().delete('/position', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  /* End Entity Position */

  // UpdateEntity: (body: any): TAction<ConfigureEntityAction, void> => {
  //   return async (dispatch: TDispatch<ConfigureEntityAction>) => {
  //     try {
  //       const response = await fetchAPI().patch('/entity', { ...body });
  //       dispatch({
  //         type: ConfigureEntityActionType.UpdateEntitySuccess,
  //         message: 'Entity updated successfully!',
  //         data: response.data,
  //       });

  //       return response.data;
  //     } catch (error) {
  //       dispatch({
  //         type: ConfigureEntityActionType.UpdateEntityError,
  //         error: 'Failed to update entity',
  //       });

  //       throw error;
  //     }
  //   };
  // },
  DownloadTemplateEntityPosition: (): TAction<ConfigureEntityAction, void> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      const response = await fetchAPI().get('/position/download/template?filename=template_b', {
        responseType: 'blob', // Set responseType to 'blob' to handle binary data
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a link element
      const link = document.createElement('a');

      // Set the download attribute and create a URL for the blob
      link.href = window.URL.createObjectURL(blob);
      link.download = 'template_b.xlsx'; // Set the desired file name

      // Append the link to the document body and programmatically click it
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);
    };
  },
  DownloadTemplateEntity: (): TAction<ConfigureEntityAction, void> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      const response = await fetchAPI().get('/entity/download/template?filename=template_a', {
        responseType: 'blob', // Set responseType to 'blob' to handle binary data
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a link element
      const link = document.createElement('a');

      // Set the download attribute and create a URL for the blob
      link.href = window.URL.createObjectURL(blob);
      link.download = 'template_a.xlsx'; // Set the desired file name

      // Append the link to the document body and programmatically click it
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);
    };
  },
  ImportEntity: (body: any): TAction<ConfigureEntityAction, void> => {
    return async (dispatch: TDispatch<ConfigureEntityAction>) => {
      try {
        const response = await fetchAPI().post('/entity/import/xlsx', body, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        return response.data;
      } catch (error) {
        throw error;
      }
    };
  },
};
