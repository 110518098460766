import { TAction, TDispatch } from "../../../types";
import {
  RCSAEntityPeriodAction,
  RCSAEntityPeriodActionType,
  RCSAEntityPeriodModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const RCSAEntityPeriodDefault: RCSAEntityPeriodModel = {
  periodData: {
    data: [],
    total: 0,
  },
};

export const RCSAEntityPeriodReducer = (
  state: RCSAEntityPeriodModel = RCSAEntityPeriodDefault,
  action: RCSAEntityPeriodAction
): RCSAEntityPeriodModel => {
  switch (action.type) {
    case RCSAEntityPeriodActionType.GetAllRSCAPeriodData:
      return { ...state, periodData: action.data };

    default:
      return { ...state };
  }
};

export const RCSAEntityPeriodCommand = {
  GetAllRSCAPeriodData: (params: any): TAction<RCSAEntityPeriodAction, void> => {
    return (dispatch: TDispatch<RCSAEntityPeriodAction>) => {
      return fetchAPI().get('/periods', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: RCSAEntityPeriodActionType.GetAllRSCAPeriodData,
        });
      });
    }
  },
  GetTaxonomyByGroupData: (params: any): TAction<RCSAEntityPeriodAction, void> => {
    return (dispatch: TDispatch<RCSAEntityPeriodAction>) => {
      return fetchAPI().get('/period/by-group', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: RCSAEntityPeriodActionType.GetAllRSCAPeriodData,
        });
      });
    }
  },
  CreateRSCAPeriodData: (body: any): TAction<RCSAEntityPeriodAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<RCSAEntityPeriodAction>) => {
      try {
        const response = await fetchAPI().post('/period', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateRSCAPeriodData: (body: any): TAction<RCSAEntityPeriodAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<RCSAEntityPeriodAction>) => {
      try {
        const response = await fetchAPI().patch('/period', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteRSCAPeriodData: (body: any): TAction<RCSAEntityPeriodAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<RCSAEntityPeriodAction>) => {
      try {
        const response = await fetchAPI().delete('/period', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
