import { TAction, TDispatch } from "../../../types";
import { CriteriaAction, CriteriaActionType, CriteriaModel } from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const CriteriaDefault: CriteriaModel = {
  criteriaData: {
    data: [],
    total: 0,
  },
  criteriaDetail: {
    data: {} as any,
  }
};

export const CriteriaReducer = (
  state: CriteriaModel = CriteriaDefault,
  action: CriteriaAction
): CriteriaModel => {
  switch (action.type) {
    case CriteriaActionType.GetAllCriteriaData:
      return { ...state, criteriaData: action.data };
    case CriteriaActionType.GetDetailCriteriaData:
      return { ...state, criteriaDetail: action.data as any};

    default:
      return { ...state };
  }
};

export const CriteriaCommand = {
  GetAllCriteriaData: (params: any): TAction<CriteriaAction, void> => {
    return (dispatch: TDispatch<CriteriaAction>) => {
      return fetchAPI().get('/criteries', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: CriteriaActionType.GetAllCriteriaData,
        });
      });
    }
  },
  GetDetailCriteriaData: (params: any): TAction<CriteriaAction, void> => {
    return (dispatch: TDispatch<CriteriaAction>) => {
      return fetchAPI().get('/criteria', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: CriteriaActionType.GetDetailCriteriaData,
        });
      });
    }
  },
  CreateCriteriaData: (body: any): TAction<CriteriaAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<CriteriaAction>) => {
      try {
        const response = await fetchAPI().post('/criteria', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  UpdateCriteriaData: (body: any): TAction<CriteriaAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<CriteriaAction>) => {
      try {
        const response = await fetchAPI().patch('/criteria', body);
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  DeleteCriteriaData: (body: any): TAction<CriteriaAction, AxiosResponse<any, any>> => {
    return async (dispatch: TDispatch<CriteriaAction>) => {
      try {
        const response = await fetchAPI().delete('/criteria', { params: body });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};
