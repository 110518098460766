import { TAction, TDispatch } from "../../../types";
import {
  ConfigureUnitPIAction,
  ConfigureUnitPIActionType,
  ConfigureUnitPIMainDataModel,
  ConfigureUnitPIModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";

export const ConfigureUnitPIDefault: ConfigureUnitPIModel = {
  main: {
    data: [],
    total: 0,
  },
};

export const ConfigureUnitPIReducer = (
  state: ConfigureUnitPIModel = ConfigureUnitPIDefault,
  action: ConfigureUnitPIAction
): ConfigureUnitPIModel => {
  switch (action.type) {
    case ConfigureUnitPIActionType.GetUnitPIMain:
      return { ...state, main: action.data };

    default:
      return { ...state };
  }
};

export const ConfigureUnitPICommand = {
  GetUnitPIMainData: (params: unknown): TAction<ConfigureUnitPIAction, void> => {
    return (dispatch: TDispatch<ConfigureUnitPIAction>) => {
      return fetchAPI().get('/unit-performances', { params }).then((response) => {
        dispatch({
          data: {
            data: response.data.data.data,
            total: response.data.data.total,
          },
          type: ConfigureUnitPIActionType.GetUnitPIMain,
        });
      });
    };
  },
};
