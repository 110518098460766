import { combineReducers } from "redux";
import {
  RCSAEntityPeriodCommand,
  RCSAEntityPeriodDefault,
  RCSAEntityPeriodReducer,
} from "./rsca/reducer";
import { ParameterSettingModel } from "./types";
import {
  TaxonomyCommand,
  TaxonomyDefault,
  TaxonomyReducer,
} from "./taxonomy/reducer";
import {
  AssignmentCommand,
  AssignmentDefault,
  AssignmentReducer,
} from "./assignment/reducer";
import {
  ProjectCommand,
  ProjectDefault,
  ProjectReducer,
} from "./project/reducer";
import {
  WorkflowCommand,
  WorkflowDefault,
  WorkflowReducer,
} from "./workflow/reducer";
import {
  KriTresholdCommand,
  KriTresholdDefault,
  KriTresholdReducer,
} from "./kri-treshold/reducer";
import {
  CriteriaCommand,
  CriteriaDefault,
  CriteriaReducer,
} from "./criteria/reducer";
import {
  TemplateMaturityCommand,
  TemplateMaturityDefault,
  TemplateMaturityReducer,
} from "./template-maturity/reducer";

const ParameterSettingReducer = combineReducers({
  rcsa: RCSAEntityPeriodReducer,
  taxonomy: TaxonomyReducer,
  assignment: AssignmentReducer,
  project: ProjectReducer,
  workflow: WorkflowReducer,
  kriTreshold: KriTresholdReducer,
  criteria: CriteriaReducer,
  templateMaturity: TemplateMaturityReducer,
});

const ParameterSettingDefault: ParameterSettingModel = {
  rcsa: RCSAEntityPeriodDefault,
  taxonomy: TaxonomyDefault,
  assignment: AssignmentDefault,
  project: ProjectDefault,
  workflow: WorkflowDefault,
  kriTreshold: KriTresholdDefault,
  criteria: CriteriaDefault,
  templateMaturity: TemplateMaturityDefault,
};

const ParameterSettingCommand = {
  rcsa: RCSAEntityPeriodCommand,
  taxonomy: TaxonomyCommand,
  assignment: AssignmentCommand,
  project: ProjectCommand,
  workflow: WorkflowCommand,
  kriTreshold: KriTresholdCommand,
  criteria: CriteriaCommand,
  templateMaturity: TemplateMaturityCommand,
};

export {
  ParameterSettingReducer,
  ParameterSettingDefault,
  ParameterSettingCommand,
};
