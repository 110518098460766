export interface RCSAEntityPeriodMainDataModel {
  ulid: string;
  code: string;
  group_ulid: string;
  period: string;
  date_start: Date | string;
  date_end: Date | string;
  description: string;
  status: string;
  name: string;
  group?: any;
  group_id?: string;
}

export interface RCSAEntityPeriodModel {
  periodData: {
    data: RCSAEntityPeriodMainDataModel[];
    total: number;
  };
}

export enum RCSAEntityPeriodActionType {
  GetEntityPeriodName = "⌘➝Parameter Setting➝RCSA Entity Period➝GetEntityPeriodName",
  GetAllRSCAPeriodData = "⌘➝Parameter Setting➝RCSA Entity Period➝GetAllRSCAPeriodData",
}

export type RCSAEntityPeriodAction = {
  data: {
    data: RCSAEntityPeriodMainDataModel[];
    total: number;
  };
  type: RCSAEntityPeriodActionType.GetAllRSCAPeriodData;
};
