import { TAction, TDispatch } from "../../../types";
import {
  DashboardAction,
  DashboardActionType,
  DashboardModel,
  DashboardChartDataModel,
  DashboardCountChartModel,
  DashboardFilterDataModel,
} from "./types";
import { fetchAPI } from "../../../../config/axios";
import { AxiosResponse } from "axios";

export const DashboardDefault: DashboardModel = {
  main: {
    data: [] as any,
  },
  kriTrend: {
    data: [] as any,
  },
  rcsaProgress: {
    data: {} as DashboardChartDataModel,
  },
  rcsaTotalOfficer: {
    data: {} as DashboardCountChartModel,
  },
  rcsaTotalDivision: {
    data: {} as DashboardCountChartModel,
  },
  rcsaTotalRisk: {
    data: {} as DashboardCountChartModel,
  },
  /* Global Chart */
  riskByType: {
    data: {} as DashboardChartDataModel,
  },
  riskProgressWorkUnit: {
    data: {} as DashboardChartDataModel,
  },
  riskByCategory: {
    data: {} as DashboardChartDataModel,
  },
  riskMonitoringStatus: {
    data: {} as DashboardChartDataModel,
  },
  riskMonitoringMitigationStatus: {
    data: {} as DashboardChartDataModel,
  },
  /* KRI Chart */
  kriTrendList: {
    data: [] as any,
  },
  kriProgress: {
    data: {} as DashboardChartDataModel,
  },
  kriReportResult: {
    data: {} as DashboardChartDataModel,
  },
  kriMonitoringStatus: {
    data: {} as DashboardChartDataModel,
  },
  kriMonitoringMitigationStatus: {
    data: {} as DashboardChartDataModel,
  },
  /* LED Chart */
  ledTotalIncident: {
    data: {} as DashboardCountChartModel,
  },
  ledTotalActualLosses: {
    data: {} as DashboardCountChartModel,
  },
  ledProgressWorkUnit: {
    data: {} as DashboardChartDataModel,
  },
  ledProgress: {
    data: {} as DashboardChartDataModel,
  },
  ledTotalLossesPerUnit: {
    data: {} as DashboardChartDataModel,
  },
  ledTop: {
    data: [] as any,
  },
  ledMonitoringStatus: {
    data: {} as DashboardChartDataModel,
  },
  ledMonitoringMitigationStatus: {
    data: {} as DashboardChartDataModel,
  },
  /* Filter Chart */
  filterDashboard: {
    data: {} as DashboardFilterDataModel,
  },
};

export const DashboardReducer = (
  state: DashboardModel = DashboardDefault,
  action: DashboardAction,
): DashboardModel => {
  switch (action.type) {
    case DashboardActionType.GetDashboardChart:
      return { ...state, main: action.data };
    case DashboardActionType.GetKRITrend:
      return { ...state, kriTrend: action.data };
    case DashboardActionType.GetRiskProgress:
      return { ...state, rcsaProgress: action.data };
    case DashboardActionType.GetRiskTotalOfficer:
      return { ...state, rcsaTotalOfficer: action.data };
    case DashboardActionType.GetRiskTotalDivision:
      return { ...state, rcsaTotalDivision: action.data };
    case DashboardActionType.GetRiskTotalRisk:
      return { ...state, rcsaTotalRisk: action.data };
    /* Global Chart */
    case DashboardActionType.GetRiskByType:
      return { ...state, riskByType: action.data };
    case DashboardActionType.GetRiskProgressWorkUnit:
      return { ...state, riskProgressWorkUnit: action.data };
    case DashboardActionType.GetRiskByCategory:
      return { ...state, riskByCategory: action.data };
    case DashboardActionType.GetRiskMonitoringStatus:
      return { ...state, riskMonitoringStatus: action.data };
    case DashboardActionType.GetRiskMonitoringMitigationStatus:
      return { ...state, riskMonitoringMitigationStatus: action.data };
    /* End Global Chart */
    /* KRI Chart */
    case DashboardActionType.GetKRITrendList:
      return { ...state, kriTrendList: action.data };
    case DashboardActionType.GetKRIProgress:
      return { ...state, kriProgress: action.data };
    case DashboardActionType.GetKRIReportResult:
      return { ...state, kriReportResult: action.data };
    case DashboardActionType.GetKRIMonitoringStatus:
      return { ...state, kriMonitoringStatus: action.data };
    case DashboardActionType.GetKRIMonitoringMitigationStatus:
      return { ...state, kriMonitoringMitigationStatus: action.data };
    /* End KRI Chart */
    /* LED Chart */
    case DashboardActionType.GetLEDTotalIncident:
      return { ...state, ledTotalIncident: action.data };
    case DashboardActionType.GetLEDTotalActualLosses:
      return { ...state, ledTotalActualLosses: action.data };
    case DashboardActionType.GetLEDProgressWorkUnit:
      return { ...state, ledProgressWorkUnit: action.data };
    case DashboardActionType.GetLEDProgress:
      return { ...state, ledProgress: action.data };
    case DashboardActionType.GetLEDTotalLossesPerUnit:
      return { ...state, ledProgress: action.data };
    case DashboardActionType.GetTopLED:
      return { ...state, ledTop: action.data };
    case DashboardActionType.GetLEDMonitoringStatus:
      return { ...state, ledMonitoringStatus: action.data };
    case DashboardActionType.GetLEDMonitoringMitigationStatus:
      return { ...state, ledMonitoringMitigationStatus: action.data };
    /* End LED Chart */
    /* Filter Dashboard */
    case DashboardActionType.GetFilterDashboard:
      return { ...state, filterDashboard: action.data };
    /* End Filter Dashboard */
    default:
      return { ...state };
  }
};

export const DashboardCommand = {
  GetDashboardChart: (params: any): TAction<DashboardAction, void> => {
    return (dispatch: TDispatch<DashboardAction>) => {
      return fetchAPI().post(`/dashboard/${params.key}`, { ...params.body }).then((response) => {
        let type = DashboardActionType.GetDashboardChart;

        if (params.key === 'kri-trend') {
          type = DashboardActionType.GetKRITrend
        } else if (params.key === 'risk-progress') {
          type = DashboardActionType.GetRiskProgress
        } else if (params.key === 'risk-by-type') {
          type = DashboardActionType.GetRiskByType
        }else if (params.key === 'risk-progress-work-unit') {
          type = DashboardActionType.GetRiskProgressWorkUnit
        } else if (params.key === 'risk-by-category') {
          type = DashboardActionType.GetRiskByCategory
        } else if (params.key === 'risk-total-officer') {
          type = DashboardActionType.GetRiskTotalOfficer
        } else if (params.key === 'risk-total-division') {
          type = DashboardActionType.GetRiskTotalDivision
        } else if (params.key === 'risk-total') {
          type = DashboardActionType.GetRiskTotalRisk
        } else if (params.key === 'risk-monitoring-status') {
          type = DashboardActionType.GetRiskMonitoringStatus
        } else if (params.key === 'risk-monitoring-mitigation-status') {
          type = DashboardActionType.GetRiskMonitoringMitigationStatus
        } else if (params.key === 'kri-trend-list') {
          type = DashboardActionType.GetKRITrendList
        } else if (params.key === 'kri-progress') {
          type = DashboardActionType.GetKRIProgress
        } else if (params.key === 'kri-report-result') {
          type = DashboardActionType.GetKRIReportResult
        } else if (params.key === 'kri-monitoring-status') {
          type = DashboardActionType.GetKRIMonitoringStatus
        } else if (params.key === 'kri-monitoring-mitigation-status') {
          type = DashboardActionType.GetKRIMonitoringMitigationStatus
        } else if (params.key === 'led-total-incident') {
          type = DashboardActionType.GetLEDTotalIncident
        } else if (params.key === 'led-total-actual-losses') {
          type = DashboardActionType.GetLEDTotalActualLosses
        } else if (params.key === 'led-progress-work-unit') {
          type = DashboardActionType.GetLEDProgressWorkUnit
        } else if (params.key === 'led-progress') {
          type = DashboardActionType.GetLEDProgress
        } else if (params.key === 'led-total-losses-per-unit') {
          type = DashboardActionType.GetLEDTotalLossesPerUnit
        } else if (params.key === 'led-top-led') {
          type = DashboardActionType.GetTopLED
        } else if (params.key === 'led-monitoring-status') {
          type = DashboardActionType.GetLEDMonitoringStatus
        } else if (params.key === 'led-monitoring-mitigation-status') {
          type = DashboardActionType.GetLEDMonitoringMitigationStatus
        }

        dispatch({
          data: {
            data: response.data.data
          },
          type: type,
        });
      });
    };
  },
  GetFilterDashboard: (body: unknown): TAction<DashboardAction, void> => {
    return (dispatch: TDispatch<DashboardAction>) => {
      return fetchAPI().post('/dashboard/filters', { filter: body }).then((response) => {
        dispatch({
          data: {
            data: response.data.data,
          },
          type: DashboardActionType.GetFilterDashboard,
        });
      });
    };
  },
};
