  /* Structure Data List KRI Monitoring */
  export interface KRIMonitoringMainDataModel {
    kri_risk_description_id: string;
    taxonomy_risk_description_id: string;
    risk_description: number;
    treshold: KRIMonitoringTresholds[];
    kri_history: any;
    value: number;
    status: string;
    kri_monitoring_id: any;
  }

  /* Structure Dependency KRI Monitoring */
  export interface KRIMonitoringDependencyDataModel {
    user_id: string;
    entity_id: string;
    position_id: string;
    position_name: string;
    division_name: string;
    directorate_name: string;
    project_assignment_id: string;
    project_name: string;
    kri_name: string;
    frequency: number;
    treshold: KRIMonitoringTresholds[];
    assignment_information: any;
    taxonomy: any;
  }

  export interface KRIMonitoringTresholds {
    id?: string;
    name: string;
    color: string;
    value: number;
    unit: string;
    flag_monitoring?: boolean;
    monitoring?: string;
  }

  /* Structure PIC KRI Monitoring */
  export interface KRIMonitoringPICDataModel {
    user_id: string;
    user_name: string;
    position_id: string;
    position_name: string;
    entity_id: string;
    entity_name: string;
    role_title: string;
  }

  /* Structure Detail KRI Monitoring */
  export interface KRIMonitoringDetailDataModel {
    ulid: string;
    code: string;
    kri_risk_description_id: string;
    kri_treshold_id: string;
    project_assignment_id: string;
    entity_id: string;
    position_id: string;
    user_id: string;
    value: any;
    description: string;
    remarks: string;
    count_incident: number;
    sum_loss_amount: any;
    status: string;
    created_at: string;
    risk_description: string;
    kri_monitoring_related_risks_notes: string;
    latest_activity: KRIMonitoringLatestActivity[];
    treshold: KRIMonitoringTresholds;
    kri_history: KRIMonitoringHistory[];
    kri_monitoring_mitigation: KRIMonitoringMitigation[];
    kri_monitoring_related_risks: any;
    priority: number;
  }

  export interface KRIMonitoringLatestActivity {
    status: string;
    modify_by: string;
    modify_date: string;
    action: string;
    notes: string;
  }

  export interface KRIMonitoringHistory {
    kri_monitoring_id: string;
    value: string;
    date: string;
    name: number;
    color: string;
    unit: string;
    monitoring: string;
  }

  export interface KRIMonitoringMitigation {
    ulid: string;
    code: string;
    taxonomy_mitigation_action_id: string;
    description: string;
    due_date: string;
    taxonomy_mitigation_status_id: string;
    timeline_status: string;
    taxonomy_control_effectiveness_id: string;
    frequency_id: string;
    evidence: string;
    notes: string;
    implementation_date: string;
    status: string;
    kri_monitoring_mitigation_pic: any;
  }

  /* Structure Risk KRI Monitoring */
  export interface KRIMonitoringRisk {
    ulid: string;
    code: string;
    risk_description: string;
    criteria_residual_impact: number;
    criteria_residual_impact_level: string;
    criteria_residual_impact_color: string;
    criteria_residual_likelihood: number;
    criteria_residual_likelihood_level: string;
    criteria_residual_likelihood_color: string;
    residual_color: string;
    residual_level: string;
    residual_tolerance: number;
  }

  export interface KRIMonitoringIncidentData {
    count_incident: number;
    sum_loss_amount: number;
    priority: any;
  }

  export interface KRIMonitoringModel {
    main: {
      data: KRIMonitoringMainDataModel[];
      total: number;
    };
    detail: {
      data: KRIMonitoringDetailDataModel;
    };
    dependency: {
      data: KRIMonitoringDependencyDataModel;
    };
    pic: {
      data: KRIMonitoringPICDataModel[];
    }
    risk: {
      data: KRIMonitoringRisk[];
    };
    incident: {
      data: KRIMonitoringIncidentData;
    }
  }
  
  export enum KRIMonitoringActionType {
    GetKRIMonitoringMainData = "⌘➝Parameter Setting➝KRIMonitoring➝GetKRIMonitoringMainData",
    GetDetailKRIMonitoringData = "⌘➝Parameter Setting➝KRIMonitoring➝GetDetailKRIMonitoringData",
    GetDependencyKRIMonitoringData = "⌘➝Parameter Setting➝KRIMonitoring➝GetDependencyKRIMonitoringData",
    GetPICKRIMonitoringData = "⌘➝Parameter Setting➝KRIMonitoring➝GetPICKRIMonitoringData",
    GetRiskKRIMonitoringData = "⌘➝Parameter Setting➝KRIMonitoring➝GetRiskKRIMonitoringData",
    GeKRIMonitoringIncidentData = "⌘➝Parameter Setting➝KRIMonitoring➝GeKRIMonitoringIncidentData",
  }
  
  export type KRIMonitoringAction = {
    data: {
      data: KRIMonitoringMainDataModel[];
      total: number;
    };
    type: KRIMonitoringActionType.GetKRIMonitoringMainData;
  } | {
    data: {
      data: KRIMonitoringDetailDataModel;
    };
    type: KRIMonitoringActionType.GetDetailKRIMonitoringData;
  } | {
    data: {
      data: KRIMonitoringDependencyDataModel;
    };
    type: KRIMonitoringActionType.GetDependencyKRIMonitoringData;
  } | {
    data: {
      data: KRIMonitoringPICDataModel[];
    };
    type: KRIMonitoringActionType.GetPICKRIMonitoringData;
  } | {
    data: {
      data: KRIMonitoringRisk[];
    };
    type: KRIMonitoringActionType.GetRiskKRIMonitoringData;
  } | {
    data: {
      data: KRIMonitoringIncidentData;
    };
    type: KRIMonitoringActionType.GeKRIMonitoringIncidentData;
  };

  