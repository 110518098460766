export interface ProjectMainDataModel {
  ulid?: string;
  code?: string;
  name?: string;
  date_start?: string;
  date_end?: string;
  properties?: any;
  key_1: string;
  period: any;
  title: string;
  description: string;
  status: string;
  tags?: string;
  taxonomy?: any;
  criteria?: any;
  group?: any;
}

export interface ProjectModel {
  projectData: {
    data: ProjectMainDataModel[];
    total: number;
  };
  projectDetail: {
    data: ProjectMainDataModel;
  };
}

export enum ProjectActionType {
  GetAllProjectData = "⌘➝Parameter Setting➝Project➝GetAllProjectData",
  GetDetailProjectData = "⌘➝Parameter Setting➝Project➝GetDetailProjectData",
}

export type ProjectAction = {
  data: {
    data: ProjectMainDataModel[];
    total: number;
  };
  type: ProjectActionType.GetAllProjectData;
} | {
  data: {
    data: ProjectMainDataModel;
  };
  type: ProjectActionType.GetDetailProjectData;
};
