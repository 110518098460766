import { combineReducers } from "redux";
import {
  RCSAEntityCommand,
  RCSAEntityDefault,
  RCSAEntityReducer,
} from "./entity/reducer";
import { RCSAModel } from "./types";

const RCSAReducer = combineReducers({
  entity: RCSAEntityReducer,
});

const RCSADefault: RCSAModel = {
  entity: RCSAEntityDefault,
};

const RCSACommand = {
  entity: RCSAEntityCommand,
};

export { RCSAReducer, RCSADefault, RCSACommand };
